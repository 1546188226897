






































import Vue from "vue";
export default Vue.extend({
  props: {
    label: { type: String },
    chips: { type: Array },
    color: { type: String },
    setFocus: { type: Boolean },
    rules: { type: Function, default: null },
    disabled: Boolean,
  },
  data() {
    return {
      items: this.chips,
      autoFocus: true,
    };
  },
  methods: {
    callBlurByTab(event: KeyboardEvent) {
      event.preventDefault();
      event.stopPropagation();
      const combobox = this.$refs.combobox;
      if (combobox) {
        (combobox as HTMLElement).blur();
      }
      this.$emit("tabPressed");
    },
    changeAutoFocus(event: FocusEvent) {
      event.preventDefault();
      event.stopPropagation();
      this.$emit("isFocused");
    },
  },
  computed: {
    getRules() {
      if (this.rules) {
        return this.rules;
      }
      return true;
    },
  },
  watch: {
    items() {
      this.$emit("update:chips", this.items);
    },
    chips() {
      this.items = this.chips;
    },
    setFocus() {
      if (this.setFocus === true) {
        this.$nextTick(() => {
          const combobox = this.$refs.combobox;
          if (combobox) {
            const input = (combobox as Vue).$el.querySelector("input");
            if (input) {
              input.focus();
            }
          }
        });
      }
    },
  },
});
