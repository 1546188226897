import httpClient from "./httpClient";
import LocaleDto from "@/types/LocaleDto";
import loginApi from "@/services/LoginApi";
import store from "@/store";
import SaveSearchResponse from "@/types/SaveSearchResponse";
import UserSearchAlertDto from "@/types/UserSearchAlertDto";
import qs from "qs";
import SearchResponse from "@/types/SearchResponse";
import { AxiosError } from "axios";
import Publication from "@/types/Publication";

const END_POINT = "/search/";

class SearchApi {
  async search(searchRequest = {}): Promise<SearchResponse> {
    const response = await httpClient
      .post(
        END_POINT.concat("publications?browserTabID=").concat(
          store.state.user.browserTabID
        ),
        JSON.stringify(searchRequest)
      )
      .catch((error) => {
        throw error.response;
      });
    return response.data;
  }
  async getLacales(userLocale: string): Promise<LocaleDto[]> {
    const { data } = await httpClient.get<LocaleDto[]>(
      END_POINT.concat("locales"),
      {
        params: { userLocale },
      }
    );
    return data;
  }
  async saveSearch(
    name: string,
    searchInfo: string,
    replace = false,
    alerts: UserSearchAlertDto[]
  ): Promise<SaveSearchResponse> {
    const session = loginApi.getSessionInfo();
    const response = await httpClient
      .post(END_POINT.concat("saveSearch"), {
        name,
        session,
        searchInfo,
        replace,
        alerts,
      })
      .catch((error) => {
        return error.response;
      });
    return new SaveSearchResponse(response.data);
  }
  async getAlertsBySearchName(
    searchName: string
  ): Promise<UserSearchAlertDto[]> {
    const session = loginApi.getSessionInfo();
    const response = await httpClient
      .post(END_POINT.concat("alerts"), {
        searchName,
        session,
      })
      .catch((error) => {
        return error.response;
      });
    return response.data;
  }
  async getAlertsBySearchId(searchId: number): Promise<UserSearchAlertDto[]> {
    const response = await httpClient
      .get(END_POINT.concat(`${searchId}/alerts`))
      .catch(() => {
        throw "Error getting alerts";
      });
    return response.data;
  }
  async updateSearchAlerts(
    searchId: number,
    alerts: UserSearchAlertDto[]
  ): Promise<void> {
    const session = loginApi.getSessionInfo();
    const response = await httpClient
      .put(END_POINT.concat(`${searchId}/alerts`), {
        session,
        alerts,
      })
      .catch(() => {
        throw "Error updating alerts";
      });
    return response.data;
  }
  async buildQuery(
    must: string[],
    should: string[],
    notContains: string[]
  ): Promise<string> {
    const { data } = await httpClient.get<string>(
      END_POINT.concat("buildQuery"),
      {
        params: { must, should, notContains },
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false });
        },
      }
    );
    return data;
  }
  async allowedCollections(): Promise<string[]> {
    const { data } = await httpClient.get<string[]>(
      END_POINT.concat("allowedCollections")
    );
    return data;
  }
  async getPublicationComplement(publicationId: string): Promise<Publication> {
    const { data } = await httpClient.get<Publication>(
      END_POINT.concat("publications/").concat(publicationId),
      {
        params: {
          browserTabID: store.state.user.browserTabID,
        },
      }
    );
    return data;
  }
  async getFlipPages(publicationId: string, token: string): Promise<any> {
    const { data } = await httpClient.get<any>(
      END_POINT.concat("flipPage/").concat(publicationId),
      {
        params: {
          browserTabID: store.state.user.browserTabID,
          token,
        },
      }
    );
    return data;
  }
  async getNextOrPreviousDownloadUrl(
    publicationId: string,
    adjacentPublicationDirection: string
  ): Promise<Publication> {
    const { data } = await httpClient
      .get<Publication>(
        END_POINT.concat("publications/")
          .concat(publicationId)
          .concat("/")
          .concat(adjacentPublicationDirection),
        {
          params: {
            browserTabID: store.state.user.browserTabID,
          },
        }
      )
      .catch((response: AxiosError) => {
        if (response.response?.status === 204) {
          throw "No previous/next publication";
        }

        throw "Error getting previous/next publication";
      });

    return data;
  }
}

export default new SearchApi();
