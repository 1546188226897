













import Vue from "vue";
import SearchSnippet from "@/components/search_results/SearchSnippet.vue";
import PageBar from "@/components/search_results/PageBar.vue";
import { SearchInfo } from "@/store/search-module";
import Publication from "@/types/Publication";

let searchInfo = {} as SearchInfo;

export default Vue.extend({
  name: "SearchResult",
  data() {
    return {};
  },
  components: { SearchSnippet, PageBar },
  beforeCreate() {
    searchInfo = this.$store.state.search;
  },
  computed: {
    publications(): Array<Publication> {
      return searchInfo.publications;
    },
  },
  methods: {
    addAllToReport() {
      this.publications.forEach((p) => {
        p.inReport = true;
      });
      console.log("teste");
    },
  },
});
