var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:{ btn: true, 'btn-selected': _vm.selected },attrs:{"flat":"","disabled":_vm.count == 0,"title":_vm.$t('collection.' + _vm.collection.toLowerCase())},on:{"click":function($event){return _vm.$emit('click')}}},[_c('icon',{attrs:{"collection":_vm.collection,"color":_vm.iconColor}}),_c('span',{class:{
      text: true,
      'btn-selected': _vm.selected,
      'btn-disabled': _vm.count == 0,
    }},[_vm._v(" "+_vm._s(_vm.label())+" ")]),_c('span',{class:{
      text: true,
      'btn-selected': _vm.selected,
      'btn-disabled': _vm.count == 0,
    }},[_vm._v(" "+_vm._s(_vm.$n(_vm.count))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }