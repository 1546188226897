















import Vue from "vue";
import { SinceOptionEnum } from "@/types/enum/SinceOptionEnum";

export default Vue.extend({
  name: "SinceOptionList",
  data() {
    return {
      defaultSelected: {
        name: this.$root
          .$t(
            "filter.periods.".concat(
              this.$store.state.search.sinceOption ??
                SinceOptionEnum.SINCELASTVISIT
            )
          )
          .toString(),
        value:
          this.$store.state.search.sinceOption.toString() ??
          SinceOptionEnum.SINCELASTVISIT,
      },
      optionList: new Array<{ name: string; value: string }>(),
    };
  },
  created() {
    this.optionList = this.getSinceOptionsList();
    const searchInfo = this.$store.state.search;
    this.$store.watch(
      () => searchInfo.sinceOption,
      (newValue) => {
        this.defaultSelected =
          this.optionList.find((o) => newValue === o.value) ||
          this.defaultSelected;
      }
    );
  },
  methods: {
    getSinceOptionsList(): Array<{ name: string; value: string }> {
      return Object.values(SinceOptionEnum).map((option) => {
        return {
          name: this.$root.$t("filter.periods.".concat(option)).toString(),
          value: option.toString(),
        };
      });
    },
    updateSinceOption(): void {
      this.$store.commit("search/setSinceOption", this.defaultSelected);
    },
  },
});
