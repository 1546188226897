export enum CollectionEnum {
  ONLINE = "ONLINE",
  FLIP = "FLIP",
  RADIO = "RADIO",
  TV = "TV",
  VIDEO = "VIDEO",
  OFFICIAL_JOURNAL = "OFFICIAL_JOURNAL",
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  TWITTER = "TWITTER",
  YOUTUBE = "YOUTUBE",
  LINKEDIN = "LINKEDIN",
}
