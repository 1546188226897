










































































































import Vue from "vue";
import UserSearchAlertDto from "@/types/UserSearchAlertDto";
import TagField from "@/components/TagField.vue";
import TimePicker from "@/components/picker/TimePicker.vue";
import SelectKeyValuePair from "@/components/select/SelectLanguage.vue";
import UserSearchAlertDtoImpl from "@/types/UserSearchAlertDto";

const EMAIL_PATTERN = new RegExp(
  "^[=_A-Za-z0-9-\\+]+(\\.[=_A-Za-z0-9-\\+]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$"
);

let userSearchAlerts = [] as UserSearchAlertDto[];

export default Vue.extend({
  name: "ConfigAlert",
  props: {
    alerts: { type: Array as () => Array<UserSearchAlertDto> },
    errorOnConfigAlerts: { type: Boolean, default: false },
    suggestAlertTime: { type: String, default: "" },
    suggestSmartTime: { type: String, default: "" },
    wideView: Boolean,
  },
  data() {
    return {
      removeAfterChangeSupportMultipleEmails: false,
      valid: true,
      alertCheckbox: false,
      smartCheckbox: false,
      alertTime: "",
      smartTime: "",
      alertDto: new UserSearchAlertDto(),
      smartNewsLetterDto: new UserSearchAlertDto(true),
    };
  },
  mounted() {
    this.fillAlerts(this.alerts);
  },
  components: { TagField, TimePicker, SelectKeyValuePair },
  computed: {
    disableAlertTimePicker(): boolean {
      return this.alertDto.alerts.length >= 5;
    },
  },
  methods: {
    validate(): boolean {
      return (this.$refs.form as Vue & { validate: () => boolean }).validate();
    },
    buildUserSearchAlerts() {
      userSearchAlerts = [] as UserSearchAlertDto[];

      let alert = JSON.parse(JSON.stringify(this.alertDto));
      let smart = JSON.parse(JSON.stringify(this.smartNewsLetterDto));

      if (!this.alertCheckbox) {
        alert.alerts = [] as string[];
      }

      if (!this.smartCheckbox) {
        smart.alerts = [] as string[];
      }

      userSearchAlerts.push(alert);
      userSearchAlerts.push(smart);
      this.$emit("update:alerts", userSearchAlerts);
    },
    addAlertTime() {
      if (
        this.alertTime &&
        !this.alertDto.alerts.includes(this.alertTime) &&
        this.alertDto.alerts.length < 5
      ) {
        this.alertDto.alerts.push(this.alertTime);
        this.alertDto.alerts.sort();
      }
    },
    emailRules(value: Array<string>) {
      var msgError = "";
      value.forEach((v) => {
        if (!this.validateEmail(v)) {
          msgError = v.concat(this.$t("config_alert.invalid_email").toString());
        }
      });
      return msgError ? msgError : true;
    },
    timeSelectRule(value: string) {
      if (!value) {
        return this.$t("config_alert.select_time").toString();
      }
      return true;
    },
    validateEmail(email: string) {
      return EMAIL_PATTERN.test(email);
    },
    fillAlerts(alerts: UserSearchAlertDto[]) {
      if (!alerts.length) {
        this.smartNewsLetterDto = new UserSearchAlertDtoImpl(true);
        this.alertDto = new UserSearchAlertDtoImpl(false);
        this.smartCheckbox = this.suggestSmartTime !== "";
        this.alertCheckbox = this.suggestAlertTime !== "";
        this.smartTime = this.suggestSmartTime;
        this.alertTime = this.suggestAlertTime;
      }
      alerts.forEach((a) => {
        if (a.smartNewsletter) {
          if (a.alerts.length > 0) {
            this.smartCheckbox = true;
            this.smartTime = a.alerts[0];
          }
          this.smartNewsLetterDto = a;
        } else {
          if (a.alerts.length > 0) {
            this.alertCheckbox = true;
            this.alertTime = a.alerts[0];
          }
          this.alertDto = a;
        }
      });
    },
  },
  watch: {
    valid() {
      this.$emit("update:errorOnConfigAlerts", !this.valid);
    },
    alertDto: {
      handler(alertDto, oldAlertDto) {
        if (alertDto.alerts.length < 1 && oldAlertDto.alerts.length > 0) {
          this.alertTime = "";
        }
        this.buildUserSearchAlerts();
      },
      deep: true,
    },
    smartNewsLetterDto: {
      handler() {
        this.buildUserSearchAlerts();
      },
      deep: true,
    },
    alertCheckbox() {
      this.buildUserSearchAlerts();
    },
    smartCheckbox() {
      this.buildUserSearchAlerts();
    },
    smartTime() {
      if (this.smartTime) {
        this.smartNewsLetterDto.alerts = [this.smartTime];
      }
    },
    alertTime() {
      this.addAlertTime();
    },
  },
});
