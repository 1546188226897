import httpClient from "./httpClient";
import ReportInfo from "@/types/ReportInfo";

const END_POINT = "/reports/";

class ReportApi {
  async addToReport(
    reportInfo: ReportInfo,
    templateCode: number
  ): Promise<void> {
    const { data } = await httpClient
      .post<void>(END_POINT, {
        templateCode: templateCode,
        zoneDiffMinutes: new Date().getTimezoneOffset(),
        reportInfo: reportInfo,
      })
      .catch(() => {
        throw "Error adding to report";
      });
    return data;
  }
  async removeToReport(
    reportInfo: ReportInfo,
    templateCode: number
  ): Promise<void> {
    const { data } = await httpClient
      .delete<void>(END_POINT, {
        data: {
          templateCode: templateCode,
          reportInfo: reportInfo,
        },
      })
      .catch(() => {
        throw "Error removing from report";
      });
    return data;
  }
}

export default new ReportApi();
