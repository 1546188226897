

















































































































































































































import Vue from "vue";
import { CollectionEnum } from "@/types/enum/CollectionEnum";

export default Vue.extend({
  data() {
    return {
      CollectionEnum,
    };
  },
  props: {
    collection: String,
    color: { type: String, default: "#036ff4" },
    width: { type: String, default: "20px" },
    height: { type: String, default: "20px" },
  },
});
