import SessionInfo from "@/types/SessionInfo";
import Vue from "vue";
import { encode } from "@/utils/Base64";
import OnboardingControlApi from "./OnboardingControlApi";
import store from "@/store";
const cookieName = "newsstream-user";

class LoginApi {
  async isLoggedIn(): Promise<boolean> {
    const cookie = Vue.$cookies.get(cookieName);
    if (!cookie) {
      return Promise.resolve(false);
    }
    if (store.state.user.isLogedIn) {
      return Promise.resolve(true);
    }
    return new Promise((myResolve, myReject) => {
      OnboardingControlApi.checkLogin()
        .then((loggedIn) => {
          store.commit("user/setLogedIn", loggedIn);
          myResolve(loggedIn);
        })
        .catch(myReject);
    });
  }

  logout(): void {
    Vue.$cookies.remove(cookieName);
    document.cookie = cookieName + "=;expires=Fri, 02-Jan-1970 00:00:00 GMT";
    location.reload();
  }

  getSessionInfo(): SessionInfo {
    const match = Vue.$cookies.get(cookieName);
    const session = match ? JSON.parse(JSON.stringify(match)) : null;
    if (session) {
      session.locale = navigator.language;
      session.tzOffsetInMinutes = new Date().getTimezoneOffset();
    }
    return session;
  }

  getBase64SessionInfo(): string | null {
    const session = this.getSessionInfo();
    if (session) {
      return encode(JSON.stringify(this.getSessionInfo()));
    }
    return null;
  }
}

export default new LoginApi();
