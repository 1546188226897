/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
class SaveSearchResponse {
  status = "";
  constructor(data: any) {
    this.status = data.status;
  }
  isOk(): boolean {
    return this.status === "ok";
  }
  isResourceExhausted(): boolean {
    return this.status === "resourceExhausted";
  }
  isNameExists(): boolean {
    return this.status == "nameExists";
  }
  isInvalidSession(): boolean {
    return this.status === "invalidSession";
  }
  isInternalError(): boolean {
    return this.status === "internalError";
  }
}

export default SaveSearchResponse;
