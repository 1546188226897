










import Vue from "vue";

import { SearchInfo } from "@/store/search-module";

let searchInfo = {} as SearchInfo;
const pageSize = 10;

export default Vue.extend({
  name: "pageBar",
  data() {
    return {
      page: 1,
    };
  },
  beforeCreate() {
    searchInfo = this.$store.state.search;
  },
  created() {
    this.$store.watch(
      () => searchInfo.offset,
      (newValue: number) => {
        if (newValue == 0) {
          this.page = 1;
        }
      }
    );
  },
  watch: {
    page() {
      this.setOffset();
    },
  },
  methods: {
    setOffset() {
      this.$store.commit("search/setOffset", pageSize * (this.page - 1));
    },
    next() {
      this.page++;
    },
    previous() {
      this.page--;
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(searchInfo.countSelected / pageSize);
    },
    visible() {
      return (
        this.$store.state.search.countSelected > 10 && !searchInfo.searchLocked
      );
    },
  },
});
