





































































import Vue from "vue";
import ConfigAlertDialog from "@/components/monitoring/ConfigAlertDialog.vue";
import ConfigDeleteDialog from "@/components/monitoring/ConfigDeleteDialog.vue";
import SearchList from "@/components/monitoring/SearchList.vue";
import SearchResult from "@/components/search_results/SearchResult.vue";
import SearchInfoApi from "@/services/SearchesInfoApi";
import GwtPopup from "@/components/GwtPopup.vue";
import SearchResponse, { CollectionOffset } from "@/types/SearchResponse";
import ReportButton from "@/components/ReportButton.vue";
import CollectionMenu from "@/components/collection/Menu.vue";
import NewsView from "@/components/search_results/NewsView.vue";
import Chart from "@/components/monitoring/Chart.vue";
import { mapActions, mapMutations } from "vuex";
import MySearchInfo from "@/types/MySearchInfo";
import { SinceOptionEnum } from "@/types/enum/SinceOptionEnum";
import SinceOptionList from "@/components/monitoring/SinceOptionList.vue";
import MonitoringEmptyStateUnselected from "@/components/empty-state/MonitoringEmptyStateUnselected.vue";
import MonitoringEmptyStateNoResults from "@/components/empty-state/MonitoringEmptyStateNoResults.vue";
import LoginApi from "@/services/LoginApi";
import { Client } from "@/types/enum/Client";
import ArrayUtils from "@/utils/ArrayUtils";
import { SearchInfo } from "@/store/search-module";

let searchInfo = {} as SearchInfo;

export default Vue.extend({
  name: "Monitoring",
  data() {
    return {
      searchId: -1,
      searchName: "",
      showConfigAlert: false,
      showDelete: false,
      showGwtPopUp: false,
      gwtPopUpHeight: "643px",
      gwtPopUpWidth: "553px",
      searchList: new Array<MySearchInfo>(),
      loading: false,
      hasResults: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      unregister: [] as any[],
      timeoutId: 0,
      searchTimeId: 0,
      lastSearchIds: [] as number[],
      collection: "",
    };
  },
  components: {
    GwtPopup,
    SearchList,
    SearchResult,
    ConfigAlertDialog,
    ConfigDeleteDialog,
    ReportButton,
    CollectionMenu,
    NewsView,
    Chart,
    SinceOptionList,
    MonitoringEmptyStateUnselected,
    MonitoringEmptyStateNoResults,
  },
  beforeCreate() {
    searchInfo = this.$store.state.search;
  },
  created() {
    this.setDefaultValues();

    window.addEventListener("message", this.handleWindowEvent);

    this.getSearchList();
    this.unregister.push(
      this.$store.watch(
        () => searchInfo.searchLocked,
        (newValue: boolean) => {
          this.loading = newValue;
          if (newValue) window.scrollTo(0, 0);
        }
      )
    );
    this.unregister.push(
      this.$store.watch(
        () => searchInfo.offset,
        () => {
          this.doSearchAfterNextTick(false);
        }
      )
    );
    this.unregister.push(
      this.$store.watch(
        () => searchInfo.sinceOption,
        () => {
          this.setOffset(0);
          this.doSearchAfterNextTick(false);
        }
      )
    );
    this.unregister.push(
      this.$store.watch(
        () => searchInfo.selectedCollections,
        (newValue: string[]) => {
          if (this.collection != newValue[0]) {
            this.collection = newValue[0];
            this.setOffset(0);
            this.doSearchAfterNextTick(false);
          }
        }
      )
    );
    this.startNotificationVerification(5000);
  },
  beforeRouteLeave(to, from, next) {
    this.setSearchLocked(false);
    next();
  },
  destroyed() {
    window.removeEventListener("message", this.handleWindowEvent);
  },
  beforeDestroy() {
    this.unregister.forEach((unWatch) => unWatch());
    clearTimeout(this.timeoutId);
  },
  watch: {
    searchId(newVal: number) {
      this.setSinceOption(SinceOptionEnum.SINCELASTVISIT);
      this.setOffset(0);
      this.setSelectedCollections([]);
      this.doSearchAfterNextTick(true);
      let search = this.searchList.find(
        (search: MySearchInfo) => search.id == newVal
      );
      if (search && this.searchList.indexOf(search) > 0) {
        this.searchList.splice(this.searchList.indexOf(search), 1);
        this.searchList.unshift(search);
      }
    },
  },
  methods: {
    ...mapMutations("search", [
      "setSearchLocked",
      "setId",
      "setDefaultValues",
      "setSelectedCollections",
      "setOffset",
      "setSinceOption",
    ]),
    ...mapActions("visualization", ["clearVisualizationFields"]),
    clientChartEnabled() {
      const { key } = LoginApi.getSessionInfo();
      return (
        // Client 91 - Studio Clipagem can't see the chart because it decrease the search performance
        key !== Client.STUDIO_CLIPAGEM
      );
    },
    getPublicationsBySearchId(forceRequest: boolean): void {
      if (
        this.searchId < 0 ||
        (this.$store.state.search.searchLocked && !forceRequest)
      ) {
        return;
      }

      SearchInfoApi.getPublicationsBySearchId(
        this.searchId,
        searchInfo.sinceOption,
        searchInfo.selectedCollections,
        searchInfo.offset
      )
        .then(this.handleSearchResponse)
        .finally(() => {
          this.setSearchLocked(false);
        });
      this.clearVisualizationFields();
      this.setSearchLocked(true);
    },
    handleWindowEvent({ data }: MessageEvent) {
      if (
        data.operation == "closeConfigNewsExport" ||
        data.operation == "closeConfigSmartReport" ||
        data.operation == "closeGWTPopUp"
      ) {
        this.resetIframeInfo();
      } else if (data.operation == "resizeGWTPopUp") {
        this.resizePopUpSize();
      }
    },
    resizePopUpSize(): void {
      var iFrame = document.getElementById("gwtIframe") as HTMLIFrameElement;
      var popup = iFrame?.contentWindow?.document.getElementsByClassName(
        "gwt-DialogBox"
      )[0] as HTMLDivElement;
      if (popup) {
        this.gwtPopUpWidth = popup.scrollWidth + "px";
        this.gwtPopUpHeight = popup.scrollHeight + "px";
      }
    },
    getSearchList(): void {
      this.clearVisualizationFields();
      SearchInfoApi.getSearchList().then((searchList) => {
        this.searchList = searchList;
        if (this.searchId === -1 && this.$route.query.searchId) {
          const searchId = Number.parseInt(
            this.$route.query.searchId.toString()
          );
          const search = this.searchList.find((s) => s.id === searchId);
          if (!search) {
            return;
          }
          search.haveNewDocs = false;
          this.setActiveSearchAndDisableNotification(searchId, search.name);
        }
      });
    },
    resetIframeInfo(): void {
      this.showConfigAlert = false;
      this.showDelete = false;
      this.showGwtPopUp = false;
      this.gwtPopUpHeight = "643px";
      this.gwtPopUpWidth = "553px";
    },
    openSmartReportIframe(searchId: number, searchName: string): void {
      this.showGwtPopUp = true;
      this.searchId = searchId;
      this.gwtPopUpHeight = "390px";
      this.gwtPopUpWidth = "465px";

      window.postMessage(
        {
          operation: "showConfigSmartReport",
          searchId: searchId,
          name: searchName.substring(0, 32).concat("..."),
        },
        location.origin
      );
    },
    openSmartNewsletterAlerts(searchId: number): void {
      this.searchId = searchId;
      this.showConfigAlert = true;
    },
    openExportIframe(searchId: number, searchName: string): void {
      this.showGwtPopUp = true;
      this.searchId = searchId;
      this.gwtPopUpHeight = "350px";
      this.gwtPopUpWidth = "454px";

      window.postMessage(
        {
          operation: "showConfigNewsExport",
          searchId: searchId,
          name: searchName.substring(0, 32).concat("..."),
        },
        location.origin
      );
    },
    openEditSearch(searchId: number): void {
      this.setId(searchId);
      this.$store.commit("search/setSearchAction", "EDIT");
      this.$store.commit("search/setCount", 0);
      this.$router.push({
        path: "/news",
      });
    },
    openDeleteIframe(searchId: number): void {
      this.searchId = searchId;
      this.showDelete = true;
    },
    setActiveSearchAndDisableNotification(
      searchId: number,
      searchName: string
    ): void {
      this.searchId = searchId;
      this.setSelectedCollections([]);
      this.setId(searchId);
      this.searchName = searchName;
      this.$store.commit("search/setMySearchName", this.searchName);
      let search = this.searchList.find(
        (search: MySearchInfo) => search.id == searchId
      );
      if (search) search.haveNewDocs = false;
    },
    handleSearchResponse(response: SearchResponse): void {
      if (this.$router.currentRoute.path !== "/mySearch") {
        return;
      }
      const { commit } = this.$store;
      commit("search/setCollectionOffsets", response.collectionOffsets);
      commit("search/setPublications", response.page);
      this.hasResults = response.count > 0;
      commit("search/setCount", response.count);
      this.setSinceOption(response.sinceOptionFilter);

      if (!response.page || response.page.length == 0) {
        const withResult = response.collectionOffsets
          .filter((c) => c.count > 0)
          .map((c) => c.collection);
        if (withResult.length > 0) {
          this.setSelectedCollections([withResult[0]]);
        }
      } else {
        const selected = response.collectionOffsets
          .filter((c) => !c.countOnly)
          .map((c) => c.collection);
        this.collection = selected[0];
        this.setSelectedCollections(selected);
      }
    },
    getIndexesForNotificationCheck(): Array<number> {
      let searchList = this.searchList
        .filter(
          (s: MySearchInfo) =>
            !s.haveNewDocs &&
            (!s.updatedAt ||
              this.diffMinutes(new Date(s.updatedAt), new Date()) > 5)
        )
        .sort(
          (a: MySearchInfo, b: MySearchInfo) =>
            new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
        )
        .slice(0, 5)
        .map((s: MySearchInfo) => {
          return s.id;
        });
      if (ArrayUtils.numberEquals(searchList, this.lastSearchIds)) {
        searchList = [];
      }
      this.lastSearchIds = searchList;
      return searchList;
    },
    updateSearchAlertIcon(searchId: number, hasAlerts: boolean): void {
      let search = this.searchList.find(
        (search: MySearchInfo) => search.id == searchId
      );

      if (search) {
        search.alert = hasAlerts;
      }
    },
    updateNotificationOnSearches(
      searchIds: Array<number>,
      response: Array<MySearchInfo>
    ): void {
      this.searchList
        .filter((a) => searchIds.includes(a.id))
        .forEach((s) => {
          let search = response.filter((r) => r.id == s.id)[0];
          if (search) {
            s.haveNewDocs = search.haveNewDocs;
            s.updatedAt = search.updatedAt;
          }
        });
    },
    getNotificationForSearches(): void {
      if (this.$router.currentRoute.path !== "/mySearch") {
        return;
      }
      let searchIds = this.getIndexesForNotificationCheck();
      if (searchIds.length > 0) {
        this.getNotificationFor(searchIds);
      } else {
        this.startNotificationVerification(300000);
      }
    },
    getNotificationFor(searchIds: Array<number>) {
      SearchInfoApi.getSearchesNotification(searchIds)
        .then((response: Array<MySearchInfo>) => {
          if (this.$router.currentRoute.path === "/mySearch") {
            this.updateNotificationOnSearches(searchIds, response);
          }
        })
        .finally(() => {
          this.getNotificationForSearches();
        });
    },
    startNotificationVerification(delay: number): void {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        if (this.$router.currentRoute.path === "/mySearch") {
          this.getNotificationForSearches();
        }
      }, delay);
    },
    showCollectionMenu(): boolean {
      return this.$store.state.search.publications.length > 0;
    },
    allowedCollections(): Array<string> {
      if (this.showCollectionMenu()) {
        let collections = this.$store.state.search.collectionOffsets.map(
          (c: CollectionOffset) => c.collection
        );
        collections.sort();
        return collections;
      }
      return [];
    },
    diffMinutes(initialDate: Date, finalDate: Date): number {
      return (
        (Math.abs(finalDate.getTime() - initialDate.getTime()) / (1000 * 60)) %
        60
      );
    },
    doSearchAfterNextTick(forceRequest: boolean) {
      clearTimeout(this.searchTimeId);
      this.searchTimeId = setTimeout(
        () => this.getPublicationsBySearchId(forceRequest),
        100
      );
    },
  },
});
