/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import searchApi from "@/services/SearchApi";
import Publication from "@/types/Publication";

import { Module } from "vuex";

export interface VisualizationInfo {
  isOpen: boolean;
  publication: Publication;
  offsetHeight: number;
  fullContentLoading: boolean;
  flipPagination: any;
}

const mutations = {
  setIsOpen(state: VisualizationInfo, isOpen: boolean): void {
    state.isOpen = isOpen;
  },
  setPublication(state: VisualizationInfo, publication: Publication): void {
    state.publication = publication;
  },
  setFlipPagination(state: VisualizationInfo, flipPagination: any): void {
    state.flipPagination = flipPagination;
  },
  setOffsetHeight(state: VisualizationInfo, offsetHeight: number): void {
    state.offsetHeight = offsetHeight;
  },
  setFullContentLoading(
    state: VisualizationInfo,
    fullContentLoading: boolean
  ): void {
    state.fullContentLoading = fullContentLoading;
  },
};

const getters = {
  isOpen: (state: VisualizationInfo): boolean => state.isOpen,
  publication: (state: VisualizationInfo): Publication => state.publication,
  fullContentLoading: (state: VisualizationInfo): boolean =>
    state.fullContentLoading,
};

const actions = {
  clearVisualizationFields(): void {
    state.isOpen = false;
    state.publication = new Publication();
    state.offsetHeight = 0;
    state.fullContentLoading = false;
  },
  setPublicationFields({ commit }: any, publication: Publication): void {
    commit("setPublication", publication);
    commit("setIsOpen", true);
    commit("setFullContentLoading", true);
    commit("setFlipPagination", []);

    searchApi
      .getPublicationComplement(publication.id)
      .then((publicationComplement) => {
        commit("setPublication", publicationComplement);
        commit("setFlipPagination", publicationComplement.flipPagination);
      })
      .finally(() => commit("setFullContentLoading", false));
  },
};

const state: VisualizationInfo = {
  isOpen: false,
  publication: new Publication(),
  offsetHeight: 0,
  fullContentLoading: false,
  flipPagination: new Map<string, string>(),
};

const visualization: Module<VisualizationInfo, any> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

export { visualization };
