import httpClient from "./httpClient";
import OnboardingControlResponse from "@/types/OnboardingControlResponse";
import SessionInfo from "@/types/SessionInfo";

const END_POINT = "/onboarding/";

class OnboardingControlApi {
  async sendNpsScore(npsId: number, score: number): Promise<boolean> {
    const response = await httpClient.put<boolean>(
      END_POINT.concat("sendNpsScore"),
      {
        npsId,
        score,
      }
    );
    return response.status == 200;
  }

  async sendNpsReason(npsId: number, reason: string): Promise<boolean> {
    const response = await httpClient.put<boolean>(
      END_POINT.concat("sendNpsReason"),
      {
        npsId,
        reason,
      }
    );
    return response.status == 200;
  }

  async shouldShowOnboarding(
    sessionInfo: SessionInfo
  ): Promise<OnboardingControlResponse> {
    const { data } = await httpClient.post<OnboardingControlResponse>(
      END_POINT.concat("check"),
      sessionInfo
    );
    return data;
  }

  async checkLogin(): Promise<boolean> {
    const response = await httpClient.get<boolean>(
      END_POINT.concat("check-login")
    );
    return response.status == 200;
  }
}

export default new OnboardingControlApi();
