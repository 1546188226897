













































































import Vue from "vue";
import searchApi from "@/services/SearchApi";
import { SearchInfo } from "@/store/search-module";
import UserSearchAlertDto from "@/types/UserSearchAlertDto";
import store from "@/store";
import ResourceExhaustedDialog from "@/components/ResourceExhaustedDialog.vue";
import NewsButton from "@/components/NewsButton.vue";
import DialogHeader from "@/components/NewsDialogHeader.vue";
import ConfigAlert from "@/components/monitoring/ConfigAlert.vue";
import ConfigCollections from "@/components/monitoring/ConfigCollections.vue";
import eventBus from "@/event-bus";

let searchInfo = {} as SearchInfo;

export default Vue.extend({
  props: {
    getSearchBean: { type: Function },
    disableSaveSearch: { type: Boolean, default: false },
  },
  data() {
    return {
      dialog: false,
      showConfigAlert: false,
      success: false,
      replace: false,
      errorOnConfigAlerts: false,
      disabledSavebtn: false,
      existNames: [] as string[],
      msgError: "",
      searchName: "",
      showResourceLimit: false,
      alerts: [] as UserSearchAlertDto[],
      suggestAlertTime: "",
      suggestSmartTime: "",
      collections: [] as string[],
    };
  },
  components: {
    ResourceExhaustedDialog,
    NewsButton,
    DialogHeader,
    ConfigAlert,
    ConfigCollections,
  },
  beforeCreate() {
    searchInfo = this.$store.state.search;
  },
  mounted() {
    if (searchInfo.mySearchName) {
      this.$store.watch(
        () => searchInfo.savedCollections,
        (newValue) => (this.collections = newValue)
      );
    } else {
      this.collections = this.$store.state.user.allowedCollections;
    }
    eventBus.$on("show.save.monitoring", () => {
      this.dialog = true;
    });
  },
  computed: {
    btnSaveLabel() {
      return this.$data.replace ? this.$t("replace") : this.$t("save");
    },
  },
  beforeDestroy() {
    eventBus.$off("show.save.monitoring");
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.disabledSavebtn = false;
        this.searchName = searchInfo.mySearchName;
        if (this.searchName) {
          this.existNames = [...this.existNames, this.searchName];
          this.replace = true;
          this.getAlerts();
        } else {
          this.suggestAlertTime = "15:00";
          this.suggestSmartTime = "06:00";
          this.showConfigAlert = true;
        }
      } else {
        this.searchName = "";
        this.suggestAlertTime = "";
        this.suggestSmartTime = "";
        this.alerts = [] as UserSearchAlertDto[];
        this.showConfigAlert = false;
      }
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.success = false;
    },
    validateName(name: string) {
      if (this.existNames.find((v) => v === name)) {
        this.replace = true;
        this.msgError = this.$t("filter.error.search_name_exists").toString();
        return this.msgError;
      } else {
        this.replace = false;
        this.msgError = "";
        return true;
      }
    },
    async getAlerts() {
      this.alerts = await searchApi.getAlertsBySearchName(this.searchName);
      this.showConfigAlert = true;
    },
    async saveSearch() {
      setTimeout(this.doSave, 50);
    },
    async doSave() {
      (this.$refs.configAlert as Vue & { validate: () => boolean }).validate();

      if (this.errorOnConfigAlerts) {
        return;
      }

      if (!this.searchName) {
        this.msgError = this.$t("filter.error.search_name_required").toString();
        return;
      }
      this.disabledSavebtn = true;
      const searchBean = this.getSearchBean();
      searchBean.collections = this.collections;
      const result = await searchApi.saveSearch(
        this.searchName,
        JSON.stringify(searchBean),
        this.replace,
        this.alerts
      );

      if (result.isOk()) {
        store.commit("search/setMySearchName", this.searchName);
        store.commit("user/setHasMonitoring", true);
        store.commit("user/setMonitoringSaved", true);
        this.success = true;
      } else if (result.isNameExists()) {
        this.existNames = [...this.existNames, this.searchName];
        this.validateName(this.searchName);
      } else if (result.isResourceExhausted()) {
        this.showResourceLimit = true;
        this.close();
      } else {
        this.msgError = this.$t("error.unexpected").toString();
      }
      this.disabledSavebtn = false;
    },
  },
});
