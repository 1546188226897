

























































import Vue from "vue";
export default Vue.extend({
  props: {
    timestamp: { type: String },
    label: { type: String },
    clearable: { type: Boolean, default: false },
    endOfDay: { type: Boolean },
    maxLimit: { type: String },
    minLimit: { type: String },
  },
  data() {
    return {
      menu: false,
      date: "",
      dateFormatted: "",
      min: "",
      max: "",
    };
  },
  created() {
    this.date = this.timestampToDateStr(this.timestamp);
    this.$emit("update:timestamp", this.dateToTimestamp(this.date));
  },
  methods: {
    dateToTimestamp(date: string) {
      if (!date) return "";
      const jsDate = this.stringToDate(date);
      this.ajustDate(jsDate);
      return jsDate.getTime().toString();
    },
    timestampToDateStr(timestamp: string) {
      if (!timestamp) return "";
      const jsDate = new Date(Number.parseInt(timestamp));
      this.ajustDate(jsDate);
      return this.formatDate(jsDate);
    },
    formatDate(d: Date) {
      let month = (d.getMonth() + 1).toString();
      let day = d.getDate().toString();
      let year = d.getFullYear();
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      return [year, month, day].join("-");
    },
    ajustDate(jsDate: Date) {
      if (this.endOfDay) {
        jsDate.setHours(23);
        jsDate.setMinutes(59);
        jsDate.setSeconds(59);
      } else {
        jsDate.setHours(0);
        jsDate.setMinutes(0);
        jsDate.setSeconds(0);
      }
    },
    stringToDate(date: string): Date {
      const split = date.split(/[^0-9]/);
      const { parseInt } = Number;
      return new Date(
        parseInt(split[0]),
        parseInt(split[1]) - 1,
        parseInt(split[2])
      );
    },
    confirmClick() {
      this.$emit("update:timestamp", this.dateToTimestamp(this.date));
    },
  },
  watch: {
    date() {
      this.dateFormatted = this.date
        ? this.$d(this.stringToDate(this.date), "short")
        : "";
    },
    timestamp() {
      this.date = this.timestampToDateStr(this.timestamp);
    },
    maxLimit() {
      this.max = this.timestampToDateStr(this.maxLimit);
    },
    minLimit() {
      this.min = this.timestampToDateStr(this.minLimit);
    },
  },
});
