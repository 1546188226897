
































































import Vue from "vue";
import DatePicker from "./DatePicker.vue";
import KeyValuePair from "@/types/generic-types";
import NewsBubbly from "@/components/NewsBubbly.vue";
import { mapGetters } from "vuex";
import store from "@/store";
import { SearchInfo } from "@/store/search-module";
import { UserInfo } from "@/store/user-module";
let searchInfo = {} as SearchInfo;
let userInfo = {} as UserInfo;

export default Vue.extend({
  data() {
    return {
      periods: [
        "LAST24HOURS",
        "LAST48HOURS",
        "LAST7DAYS",
        "LAST30DAYS",
        "LAST45DAYS",
        "CUSTOM",
      ],
    };
  },
  beforeCreate() {
    searchInfo = this.$store.state.search;
    userInfo = this.$store.state.user;
  },
  methods: {
    ...mapGetters("user", ["isDisableFields"]),
    onboardingActive(index: number) {
      return this.isDisableFields() && userInfo.onboardingStepIndex < index - 1;
    },
    onFocus() {
      if (this.isDisableFields()) {
        this.$store.commit("user/setOnboardingStepIndex", 4);
      }
    },
  },
  computed: {
    sinceOption: {
      get: () => searchInfo.sinceOption,
      set: (newValue) => store.commit("search/setSinceOption", newValue),
    },
    until: {
      get: () => searchInfo.until,
      set: (newValue) => store.commit("search/setUntil", newValue),
    },
    since: {
      get: () => searchInfo.since,
      set: (newValue) => store.commit("search/setSince", newValue),
    },
    dateField: {
      get: () => searchInfo.dateField,
      set: (newValue) => store.commit("search/setDateField", newValue),
    },
    periodItems(): KeyValuePair<string>[] {
      return this.periods.map((s) => {
        const t: KeyValuePair<string> = {
          value: s,
          text: this.$t("filter.periods." + s).toString(),
        };
        return t;
      });
    },
  },
  components: { DatePicker, NewsBubbly },
});
