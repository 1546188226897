export default class ReportInfo {
  id!: string;
  key!: string;
  collection!: string;
  query!: string;
  must!: string[];
  should!: string[];
  notContains!: string[];
  url!: string;
  title!: string;
  source!: string;
  summary!: string;
  published!: string;
  externalUrl!: string;
  category!: string;
  restrictedSource!: boolean;
  advancedSearch!: boolean;
}
