export default class Publication {
  id!: string;
  collection!: string;
  content!: string;
  author!: string;
  nickname!: string;
  sourceId!: number;
  source!: string;
  crawledDate!: string;
  publishedDate!: string;
  editedTime!: string;
  originalUrl!: string;
  url!: string;
  title!: string;
  subtitle!: string;
  locality!: string;
  imagens!: Image[];
  terms!: string[];
  inReport!: boolean;
  page!: string;
  key!: string;
  category!: string;
  fullContent!: string;
  downloadUrl!: string;
  duration!: number;
  externalUrl!: string;
  flipPagination!: any;
  userImageUrl!: string;
  commentCount!: number;
  likeCount!: number;
  shareCount!: number;
  followerCount!: number;
}

export class Image {
  url!: string;
  caption!: string;
  credit!: string;
}
