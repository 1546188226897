
























































































































import Vue from "vue";
import TagField from "@/components/TagField.vue";
import QueryTabChangeAlert from "@/components/QueryTabChangeAlert.vue";
import store from "@/store";
import { SearchInfo } from "@/store/search-module";
import { UserInfo } from "@/store/user-module";
import searchApi from "@/services/SearchApi";
import NewsBubbly from "@/components/NewsBubbly.vue";
import { mapGetters } from "vuex";
import eventBus from "@/event-bus";

const SIMPLE_TAB = "tab-simple";
const ADVANCED_TAB = "tab-advanced";
let searchInfo = {} as SearchInfo;
let userInfo = {} as UserInfo;

export default Vue.extend({
  name: "FilterQueryPanel",
  data() {
    return {
      tab: SIMPLE_TAB,
      queryHist: "",
      queryChangeAlert: false,
      tagFocused: "",
    };
  },
  components: { TagField, QueryTabChangeAlert, NewsBubbly },
  methods: {
    ...mapGetters("user", ["isDisableFields"]),
    onboardingActive(index: number) {
      if (!this.isDisableFields()) return false;
      if (index !== 3 || this.must.length > 0 || this.should.length > 0) {
        return userInfo.onboardingStepIndex < index - 1;
      } else {
        return userInfo.onboardingStepIndex < index;
      }
    },
    onOnboardingStepNext() {
      const { must, should, notContains } = this.$refs;

      let element = [must, should, notContains][
        userInfo.onboardingStepIndex - 1
      ] as Vue;

      if (!element) return;
      const input = element.$el.querySelector("input");

      if (!input) return;
      this.$nextTick(() => {
        input.focus();
      });
    },
    doSearch() {
      if (this.queryHist != this.advancedQuery) {
        eventBus.$emit("doSearch");
      }
    },
    changeFocusToNextTag(nextTag: string) {
      this.tagFocused = nextTag;
    },
    updateCurrentTagFocused(focused: string, onboardingStepIndex: number) {
      this.tagFocused = focused;
      if (onboardingStepIndex > 1) {
        this.$store.commit("user/setOnboardingStepIndex", onboardingStepIndex);
      }
    },
    goToSimple() {
      store.commit("search/setAdvancedQuery", "");
      this.queryHist = "";
      this.queryChangeAlert = false;
    },
    stayInAdvanced() {
      this.tab = ADVANCED_TAB;
      this.queryChangeAlert = false;
    },
    showAdvancedHelp() {
      eventBus.$emit("showAdvancedHelp");
    },
    async buildQuery() {
      if (this.advancedQuery) {
        return;
      }
      const newQuery = await searchApi.buildQuery(
        this.must,
        this.should,
        this.notContains
      );
      store.commit("search/setAdvancedQuery", newQuery);
      this.queryHist = newQuery;
    },
  },
  beforeCreate() {
    searchInfo = this.$store.state.search;
    userInfo = this.$store.state.user;
  },
  created() {
    store.watch(
      () => searchInfo.advancedSearch,
      () => (this.tab = this.advancedSearch ? ADVANCED_TAB : SIMPLE_TAB)
    );
    store.watch(() => userInfo.onboardingStepIndex, this.onOnboardingStepNext);
  },
  watch: {
    tab() {
      if (this.tab === ADVANCED_TAB) {
        this.buildQuery();
      } else if (this.queryHist != this.advancedQuery) {
        this.queryChangeAlert = true;
      } else {
        this.goToSimple();
      }
      store.commit("search/setAdvancedSearch", this.tab === ADVANCED_TAB);
    },
  },
  computed: {
    advancedQuery: {
      get: () => searchInfo.advancedQuery,
      set: (newValue) => store.commit("search/setAdvancedQuery", newValue),
    },
    advancedSearch: {
      get: () => searchInfo.advancedSearch,
      set: (newValue) => store.commit("search/setAdvancedSearch", newValue),
    },
    must: {
      get: () => searchInfo.must,
      set: (newValue) => store.commit("search/setMust", newValue),
    },
    should: {
      get: () => searchInfo.should,
      set: (newValue) => store.commit("search/setShould", newValue),
    },
    notContains: {
      get: () => searchInfo.notContains,
      set: (newValue) => store.commit("search/setNotContains", newValue),
    },
  },
});
