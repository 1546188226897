





























































































































































































































































































































































































































































































































































































































import Vue from "vue";
export default Vue.extend({
  name: "SearchHelp",
  props: {
    searchHelp: Boolean,
  },
  methods: {
    close() {
      this.$emit("update:searchHelp", false);
    },
  },
});
