





















































































































































































import Vue from "vue";
import { PropType } from "vue";

import Icon from "@/components/collection/Icon.vue";
import Publication from "@/types/Publication";
import ReportInfo from "@/types/ReportInfo";
import ReportApi from "@/services/ReportApi";
import { mapActions } from "vuex";
import { SearchInfo } from "@/store/search-module";
import { CollectionEnum } from "@/types/enum/CollectionEnum";
import { VisualizationInfo } from "@/store/visualization-module";
import { isSocialMedia, iconColor } from "@/utils/CollectionUtils";
import { publicationImgErro } from "@/utils/ImageUtils";
import i18n from "@/i18n";

let searchInfo = {} as SearchInfo;
let visualizationInfo = {} as VisualizationInfo;

export default Vue.extend({
  name: "SearchResult",
  data() {
    return {
      addReport: false,
      showAddAllReport: false,
      isAddAllReport: false,
    };
  },
  components: { Icon },
  props: {
    inReport: { type: Boolean },
    publication: { type: Object as PropType<Publication> },
    addAllToReport: { type: Function },
  },
  created() {
    this.addReport = this.inReport;
  },
  computed: {
    isSocialMedia(): boolean {
      return isSocialMedia(this.publication.collection);
    },
    socialTitle() {
      return this.publication.author || this.publication.source;
    },
    countItemsInReport() {
      return this.$store.state.user.countItemsInReport;
    },
    imagemUrl() {
      if (this.publication.imagens) {
        return this.publication.imagens[0].url;
      }

      let collection = this.publication.collection.toUpperCase();
      if (collection == CollectionEnum.FLIP) {
        return this.publication.url;
      }

      return "";
    },
  },
  beforeCreate() {
    searchInfo = this.$store.state.search;
    visualizationInfo = this.$store.state.visualization;
  },
  watch: {
    addReport() {
      this.addToReport();
      if (this.addReport) {
        this.showAddAllReport = true;
        setTimeout(() => (this.showAddAllReport = false), 5000);
      } else {
        this.isAddAllReport = false;
      }
    },
    countItemsInReport() {
      if (this.countItemsInReport < 1) {
        this.addReport = false;
        this.publication.inReport = false;
        this.isAddAllReport = false;
      }
    },
    isAddAllReport() {
      if (this.isAddAllReport) {
        this.addAllToReport();
      }
    },
    inReport() {
      this.addReport = this.inReport;
    },
  },
  methods: {
    ...mapActions("visualization", [
      "clearVisualizationFields",
      "setPublicationFields",
    ]),
    onImageError() {
      const img = this.$refs.cardImage as HTMLImageElement;
      publicationImgErro(img, this.publication);
    },
    async addToReport() {
      let info = new ReportInfo();
      info.id = this.publication.id;
      info.collection = this.publication.collection;
      info.key = this.publication.key;
      info.url = this.publication.originalUrl
        ? this.publication.originalUrl
        : this.publication.url;
      info.title = this.getItemTitle(this.publication);
      info.source = this.getSourceNameToReport(this.publication);
      info.summary = this.publication.content;
      info.published = this.publication.publishedDate;
      info.externalUrl = this.publication.url;
      info.restrictedSource = false;
      info.advancedSearch = searchInfo.advancedSearch;
      info.category = searchInfo.mySearchName;

      if (!searchInfo.advancedSearch) {
        info.must = searchInfo.must;
        info.should = searchInfo.should;
        info.notContains = searchInfo.notContains;
      } else {
        info.query = searchInfo.advancedQuery;
      }

      if (this.addReport) {
        ReportApi.addToReport(info, this.$store.state.user.lastReportTemplate);
        this.$store.commit("user/plusCountItemsInReport", 1);
      } else {
        ReportApi.removeToReport(
          info,
          this.$store.state.user.lastReportTemplate
        );
        this.$store.commit("user/subtractCountItemsInReport", 1);
      }
    },
    getSourceNameToReport(publication: Publication) {
      switch (publication.collection) {
        case CollectionEnum.LINKEDIN:
        case CollectionEnum.FACEBOOK:
        case CollectionEnum.INSTAGRAM:
        case CollectionEnum.TWITTER:
        case CollectionEnum.YOUTUBE:
          return publication.collection
            .toLowerCase()
            .concat(publication.source ? " - " + publication.source : "");
        default:
          return publication.source;
      }
    },
    getIconColor(): string {
      return iconColor(true, 1, this.publication.collection, true);
    },
    parsedDate(publication: Publication) {
      if (publication.publishedDate) {
        return Date.parse(publication.publishedDate);
      }

      return Date.parse(publication.crawledDate);
    },
    getItemTitle(publication: Publication) {
      if (publication.title) {
        return publication.title;
      }
      if (publication.content) {
        const length = Math.min(100, publication.content.length);
        return publication.content.substring(0, length).concat("...");
      }
      const untitled = this.$t("no_title").toString();
      return untitled;
    },
    openVisualizationView() {
      this.clearVisualizationFields();
      this.setPublicationFields(this.publication);
      this.$store.commit(
        "visualization/setOffsetHeight",
        this.$el.getBoundingClientRect().top + window.scrollY
      );
    },
    isSelected(): boolean {
      return this.publication.id == visualizationInfo.publication.id;
    },
  },
});
