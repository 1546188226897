const encode = function (str: string): string {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p) => {
      return String.fromCharCode(Number.parseInt("0x" + p));
    })
  );
};
const decode = function (str: string): string {
  return decodeURIComponent(
    atob(str)
      .split("")
      .map((c) => {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
};

export { encode, decode };
