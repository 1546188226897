import { render, staticRenderFns } from "./SearchEmptyStateNoTerms.vue?vue&type=template&id=528b238e&scoped=true&"
import script from "./SearchEmptyStateNoTerms.vue?vue&type=script&lang=ts&"
export * from "./SearchEmptyStateNoTerms.vue?vue&type=script&lang=ts&"
import style0 from "./SearchEmptyStateNoTerms.vue?vue&type=style&index=0&id=528b238e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "528b238e",
  null
  
)

export default component.exports