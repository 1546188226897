















































import Vue from "vue";
import DialogReader from "@/components/NewsDialogHeader.vue";
import NewsButton from "@/components/NewsButton.vue";
import OnboardingControlApi from "@/services/OnboardingControlApi";

export default Vue.extend({
  name: "Survey",
  data() {
    return {
      score: 0,
      reason: "",
    };
  },
  components: { DialogReader, NewsButton },
  methods: {
    send() {
      OnboardingControlApi.sendNpsReason(
        this.$store.state.user.npsSurvey,
        this.reason
      ).then(() => {
        this.$store.commit("user/setNpsSurvey", 0);
        this.$router.push({
          path: "/mySearch",
        });
      });
    },
    async sendNpsScore() {
      OnboardingControlApi.sendNpsScore(
        this.$store.state.user.npsSurvey,
        this.score
      );
    },
  },
  watch: {
    score() {
      this.sendNpsScore();
    },
  },
});
