/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import LoginApi from "./LoginApi";

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: { "Content-Type": "application/json" },
  timeout: 600000,
});

const authInterceptor = (config: any) => {
  config.headers["sessionInfo"] = LoginApi.getBase64SessionInfo();
  return config;
};

const errorInterceptor = (error: any) => {
  // check if it's a server error
  if (!error.response) {
    return Promise.reject(error);
  }

  // all the other error responses
  switch (error.response.status) {
    case 401:
      LoginApi.logout();
      break;

    default:
      console.error(error.response.status, error.message);
  }
  return Promise.reject(error);
};

// Interceptor for responses
const responseInterceptor = (response: any) => {
  switch (response.status) {
    case 200:
      break;
    default:
  }

  return response;
};

httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);
httpClient.interceptors.request.use(authInterceptor);

export default httpClient;
