















import Vue from "vue";
import array from "@/utils/ArrayUtils";
import MenuButton from "@/components/collection/MenuButton.vue";
import { SearchInfo } from "@/store/search-module";
import { CollectionOffset } from "@/types/SearchResponse";

let searchInfo = {} as SearchInfo;

export default Vue.extend({
  data() {
    return {
      selected: [] as string[],
      allCount: 0,
    };
  },
  components: { MenuButton },
  props: {
    allowedCollections: {
      type: Array as () => Array<string>,
      default: () => [],
    },
  },
  created() {
    searchInfo = this.$store.state.search;
    this.allCount = searchInfo.count;
  },
  mounted() {
    this.$store.watch(
      () => searchInfo.selectedCollections,
      (newValue) => {
        if (!array.stringEquals(newValue, this.selected)) {
          this.selected = newValue;
        }
      }
    );
    this.$store.watch(
      () => searchInfo.count,
      (newValue) => {
        this.allCount = newValue;
      }
    );
    const userInfo = this.$store.state.user;
    this.initSelection(userInfo.allowedCollections);
    this.$store.watch(() => userInfo.allowedCollections, this.initSelection);
  },
  methods: {
    initSelection(allowedCollections: string[]): void {
      const allowedOffset = allowedCollections.map(
        (c) => new CollectionOffset(c)
      );
      this.$store.commit("search/setCollectionOffsets", allowedOffset);
      if (searchInfo.selectedCollections.length > 0) {
        this.selected = [searchInfo.selectedCollections[0]];
      } else if (this.allowedCollections.length > 0) {
        this.selected = [this.allowedCollections[0]];
      } else {
        this.selected = [];
      }
    },
    getCollCount(collection: string) {
      return searchInfo.collectionOffsets.find(
        (o) => o.collection === collection
      )?.count;
    },
    collectionClick(collection: string) {
      this.selected = [collection];
    },
    isSelected(collection: string): boolean {
      return this.selected.includes(collection);
    },
  },
  watch: {
    selected() {
      this.$store.commit("search/setSelectedCollections", this.selected);
    },
    allowedCollections() {
      if (this.selected.length == 0 && this.allowedCollections.length > 0) {
        this.$store.commit("search/setSelectedCollections", [
          this.allowedCollections[0],
        ]);
      }
    },
  },
});
