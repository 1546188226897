



import Vue from "vue";
import ApexCharts from "apexcharts";
import chartApi from "@/services/ChartApi";

export default Vue.extend({
  props: {
    searchId: { type: Number, default: -1 },
    searchName: String,
  },
  data() {
    return {
      chart: {} as ApexCharts,
    };
  },
  mounted() {
    const options = {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          export: {
            csv: {
              filename: this.searchName,
            },
            svg: {
              filename: this.searchName,
            },
            png: {
              filename: this.searchName,
            },
          },
        },
      },
      series: [
        {
          name: this.$t("monitoring.chart.serie"),
          data: [],
        },
      ],
      plotOptions: {
        bar: {
          borderRadius: 5,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        labels: {
          show: true,
          rotate: -90,
          rotateAlways: true,
        },
      },
      title: {
        text: this.$t("monitoring.chart.title"),
        floating: true,
        offsetY: 1,
        margin: 20,
        align: "center",
        style: {
          color: "#444",
        },
      },
      legend: {
        show: true,
        showForSingleSeries: true,
      },
      noData: {
        text: this.$t("loading"),
        align: "center",
        verticalAlign: "middle",
      },
    };

    this.chart = new ApexCharts(document.querySelector("#chart"), options);
    this.chart.render();
    this.loadSeries();
  },
  methods: {
    async loadSeries() {
      this.chart.updateSeries([
        {
          data: [],
        },
      ]);
      if (this.searchId < 0) {
        return;
      }
      const data = [] as any[];
      const response = await chartApi.getLastSevenDays(this.searchId);
      response.forEach((v, k) => {
        data.push({
          x: k,
          y: v.reduce((v, c) => v + c.count, 0),
        });
      });
      this.chart.updateSeries([
        {
          name: this.$t("monitoring.chart.serie").toString(),
          data,
        },
      ]);
    },
  },
  watch: {
    searchId() {
      this.loadSeries();
    },
  },
});
