

































import Vue from "vue";
import NewsButton from "@/components/NewsButton.vue";
import eventBus from "@/event-bus";

export default Vue.extend({
  props: {
    show: Boolean,
  },
  data() {
    return {
      dialog: this.show,
      image: "/vue/img/popup_duvida_sim_ou_nao.svg",
      btn1Show: true,
      btn1Label: this.$t("no"),
      btn1Click: new Function(),
      btn2Show: true,
      btn2Label: this.$t("yes"),
      btn2Click: new Function(),
      exitAcion: new Function(),
      msg1: this.$t("filter.onboarding.asking"),
      msg2: "",
    };
  },
  components: { NewsButton },
  mounted() {
    this.setUpOnboarding();
    // eslint-disable-next-line @typescript-eslint/ban-types
    eventBus.$on("doShowOnboarding", (exitAcion: Function) => {
      this.setUpOnboarding();
      if (exitAcion) this.exitAcion = exitAcion;
      this.dialog = true;
    });
    // eslint-disable-next-line @typescript-eslint/ban-types
    eventBus.$on("doAskForMonitoring", (exitAcion: Function) => {
      this.setUpAskForMonitoring();
      if (exitAcion) this.btn1Click = exitAcion;
      this.dialog = true;
    });
  },
  beforeDestroy() {
    eventBus.$off("doShowOnboarding");
    eventBus.$off("doAskForMonitoring");
  },
  methods: {
    setUpOnboarding() {
      this.btn2Click = this.yesSatisfied;
      this.btn1Click = this.noSatisfied;
      this.image = "/vue/img/popup_duvida_sim_ou_nao.svg";
      this.btn1Show = true;
      this.btn1Label = this.$t("no");
      this.btn2Show = true;
      this.btn2Label = this.$t("yes");
      this.msg1 = this.$t("filter.onboarding.asking");
      this.msg2 = "";
    },
    setUpAskForMonitoring() {
      this.btn2Click = this.openSaveMonitoring;
      this.btn1Click = () => (this.dialog = false);
      this.image = "/vue/img/popup_cliente_sem_monitoramento.svg";
      this.btn1Show = true;
      this.btn1Label = this.$t("filter.onboarding.not_yet");
      this.btn2Show = true;
      this.btn2Label = this.$t("filter.monitoring");
      this.msg1 = this.$t("filter.onboarding.no_monitoring_p1");
      this.msg2 = this.$t("filter.onboarding.no_monitoring_p2").toString();
    },
    doShowOnboarding() {
      this.$store.commit("user/setOnboardingStepIndex", 0);
      this.dialog = true;
    },
    updateDialog() {
      this.dialog = this.show;
    },
    yesSatisfied() {
      this.image = "/vue/img/popup_parabens_primeira_busca.svg";
      this.msg1 = this.$t("filter.onboarding.success_p1");
      this.msg2 = this.$t("filter.onboarding.success_p2").toString();
      this.btn1Label = this.$t("filter.onboarding.not_yet");
      this.btn1Click = () => {
        this.dialog = false;
        this.exitAcion();
      };
      this.btn2Label = this.$t("filter.monitoring");
      this.btn2Click = this.openSaveMonitoring;
    },
    noSatisfied() {
      this.image = "/vue/img/pop_refinar_busca.svg";
      this.msg1 = this.$t("filter.onboarding.retry_p1");
      this.msg2 = this.$t("filter.onboarding.retry_p2").toString();
      this.btn1Show = false;
      this.btn2Click = () => (this.dialog = false);
      this.btn2Label = this.$t("lets_go");
    },
    openSaveMonitoring() {
      this.dialog = false;
      eventBus.$emit("show.save.monitoring");
    },
  },
  watch: {
    show() {
      this.updateDialog();
    },
  },
});
