import Vue from "vue";
import LoginApi from "@/services/LoginApi";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "../views/Login.vue";
import Monitoring from "../views/Monitoring.vue";
import SavedReports from "../views/SavedReports.vue";
import SmartReports from "../views/SmartReports.vue";
import Search from "@/views/Search.vue";
import EmailGroup from "../views/EmailGroup.vue";
import FlipStand from "../views/FlipStand.vue";
import Account from "../views/Account.vue";
import Consumption from "../views/Consumption.vue";
import SocialFinder from "../views/SocialFinder.vue";
import Survey from "../views/Survey.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "MonitoringRoot",
    component: Monitoring,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/news",
    name: "news",
    component: Search,
  },
  {
    path: "/tv",
    name: "tv",
    component: Search,
  },
  {
    path: "/radio",
    name: "radio",
    component: Search,
  },
  {
    path: "/video",
    name: "video",
    component: Search,
  },
  {
    path: "/mySearch",
    name: "Monitoring",
    component: Monitoring,
  },
  {
    path: "/myReport",
    name: "savedReports",
    component: SavedReports,
  },
  {
    path: "/smartReports",
    name: "smartReports",
    component: SmartReports,
  },
  {
    path: "/emailGroup",
    name: "emailGroup",
    component: EmailGroup,
  },
  {
    path: "/social",
    name: "SocialFinder",
    component: SocialFinder,
  },
  {
    path: "/flipStand",
    name: "FlipStand",
    component: FlipStand,
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
  },
  {
    path: "/consumption",
    name: "Consumption",
    component: Consumption,
  },
  {
    path: "/survey",
    name: "Survey",
    component: Survey,
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.fullPath.match(/\/login$/g)) {
    next();
    return;
  }
  LoginApi.isLoggedIn().then((isLoggedIn) => {
    if (!isLoggedIn) {
      next({
        path: "/login",
      });
    } else if (to.fullPath.match(/\/\w+=/g)) {
      const oldUrlParams = to.fullPath.split("=");
      const path = oldUrlParams[0].replace("/", "");
      const hashBean = oldUrlParams[1];
      next({
        path,
        query: { bean: hashBean },
      });
    } else {
      next();
    }
  });
});

export default router;
