









import Vue from "vue";
import "./css/global.css";
import Navbar from "./components/Navbar.vue";

export default Vue.extend({
  name: "App",
  data: () => ({
    //
  }),
  components: { Navbar },
  methods: {
    showNavbar() {
      return this.$router.currentRoute.path != "/login";
    },
  },
});
