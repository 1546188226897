





























import Vue from "vue";
import Icon from "@/components/collection/Icon.vue";
import { iconColor } from "@/utils/CollectionUtils";

export default Vue.extend({
  props: {
    collection: { type: String },
    count: { type: Number, default: 0 },
    selected: { type: Boolean, default: true },
  },
  components: { Icon },
  methods: {
    label(): string {
      const key =
        "collection." + this.$props.collection.toLowerCase() + "_short";
      if (this.$te(key)) {
        return this.$t(key).toString();
      } else {
        return this.$t(
          "collection." + this.$props.collection.toLowerCase()
        ).toString();
      }
    },
  },
  computed: {
    iconColor(): string {
      return iconColor(this.selected, this.count, this.collection, false);
    },
  },
});
