















import Vue from "vue";
import store from "@/store";
import GwtPopup from "@/components/GwtPopup.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  data() {
    return {
      sourcesTitle: this.$t("filter.sources"),
      show: false,
      height: "643px",
      width: "550px",
      disabled: true,
    };
  },
  components: { GwtPopup },
  mounted() {
    window.addEventListener("message", this.sourcesSelected);
  },
  destroyed() {
    window.removeEventListener("message", this.sourcesSelected);
  },
  methods: {
    ...mapGetters("user", ["isDisableFields"]),
    showSourcesDialog() {
      this.show = true;
      window.postMessage({ operation: "showSourcesDialog" }, location.origin);
    },
    sourcesSelected({ data }: MessageEvent) {
      if (data.operation === "sourcesSelected") {
        const selection = JSON.parse(data.data);
        store.commit("search/setSources", selection.sources);
        store.commit("search/setGroupIds", selection.groupIds);
        store.commit("search/setTagsIds", selection.tagsIds);
        store.commit("search/setLocalities", selection.localities);
        store.commit(
          "search/setRestrictedSources",
          selection.restrictedSources
        );
        store.commit(
          "search/setRestrictedLocalities",
          selection.restrictedLocalities
        );
      } else if (data.operation === "sourcesLabel") {
        this.sourcesTitle = data.data || this.$t("filter.sources");
      } else if (data.operation === "sourcesClosed") {
        this.show = false;
      } else if (data.operation == "gwtLoaded") {
        this.disabled = false;
      } else if (data.operation == "sourceDialogResize") {
        this.height = data.data ? data.data + "px" : "643px";
      }
    },
  },
  computed: {
    sourcesLabel() {
      const label = this.sourcesTitle as string;
      if (this.sourcesTitle.length > 30) {
        return label.substring(0, 30) + "...";
      }
      return label;
    },
  },
  watch: {
    show() {
      if (this.show === false) {
        window.postMessage(
          { operation: "closeSourcesDialog" },
          location.origin
        );
      }
    },
  },
});
