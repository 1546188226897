




import Vue from "vue";
import GwtIframe from "../components/GwtIframe.vue";

export default Vue.extend({
  name: "smartReports",
  data() {
    return {};
  },
  components: { GwtIframe },
});
