




























import Vue, { PropType } from "vue";

export default Vue.extend({
  props: {
    collections: { type: Array as PropType<string[]> },
    invalid: Boolean,
  },
  data() {
    return {
      selected: this.collections,
    };
  },
  computed: {
    all() {
      return (
        this.$store.state.user.allowedCollections.length ===
        this.$data.selected.length
      );
    },
  },
  methods: {
    click(collcection: string) {
      if (this.selected.includes(collcection)) {
        this.selected = this.selected.filter((c: string) => c !== collcection);
      } else {
        this.selected.push(collcection);
      }
    },
    allChange(select: boolean) {
      if (select) {
        this.selected = this.$store.state.user.allowedCollections;
      } else {
        this.selected = [];
      }
    },
  },
  watch: {
    selected() {
      this.$emit("update:invalid", this.selected.length == 0);
      this.$emit("update:collections", this.selected);
    },
  },
});
