





































































import Vue from "vue";
import Icon from "@/components/collection/Icon.vue";
import { mapActions } from "vuex";
import { VisualizationInfo } from "@/store/visualization-module";
import { iconColor } from "@/utils/CollectionUtils";
import Publication from "@/types/Publication";
import { publicationImgErro } from "@/utils/ImageUtils";

let visualizationInfo = {} as VisualizationInfo;

export default Vue.extend({
  name: "NewsView",
  data() {
    return { scrollPosition: window.scrollY };
  },
  beforeCreate() {
    visualizationInfo = this.$store.state.visualization;
  },
  created() {
    document.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollListener);
  },
  computed: {
    publication(): Publication {
      return visualizationInfo.publication;
    },
    socialTitle(): string {
      const title = this.publication.author || this.publication.source;
      if (title) {
        return title;
      }
      const { fullContent } = this.publication;
      if (fullContent) {
        return (
          fullContent.substring(0, Math.min(fullContent.length, 50)) + "..."
        );
      }
      return "Post";
    },
    topPosition(): string {
      return Math.max(140 - this.scrollPosition, 0) + "px";
    },
    height(): string {
      const diff = (this.scrollPosition / window.innerHeight) * 100;
      return Math.min(85 + diff, 100) + "vh";
    },
  },
  methods: {
    ...mapActions("visualization", ["clearVisualizationFields"]),
    onImageError() {
      const img = this.$refs.mainImage as HTMLImageElement;
      publicationImgErro(img, this.publication);
    },
    closeVisualization() {
      this.clearVisualizationFields();
    },
    getIconColor(): string {
      return iconColor(true, 1, this.publication.collection, true);
    },
    scrollListener() {
      this.scrollPosition = window.scrollY;
    },
    parsedDate(publication: Publication) {
      if (publication.publishedDate) {
        return Date.parse(publication.publishedDate);
      }

      return Date.parse(publication.crawledDate);
    },
  },
  components: { Icon },
});
