






























































import Vue from "vue";

export default Vue.extend({
  name: "TimePicker",
  props: {
    time: { type: String, default: null },
    rules: { type: Function, default: null },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      timeValue: "00:00",
      menu: false,
    };
  },
  created() {
    this.timeValue = this.time;
  },
  methods: {
    updateTime() {
      this.menu = false;
      this.$emit("update:time", this.timeValue);
    },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getRules(): any {
      if (this.rules) {
        return this.rules;
      }
      return true;
    },
  },
  watch: {
    time() {
      this.timeValue = this.time;
    },
  },
});
