
































import Vue from "vue";
import FilterPeriodPanel from "./FilterPeriodPanel.vue";
import FilterQueryPanel from "@/components/FilterQueryPanel.vue";
import FilterLanguage from "./FilterLanguage.vue";
import FilterPlusOptionsPanel from "./FilterPlusOptionsPanel.vue";
import NewsButton from "@/components/NewsButton.vue";
import SimpleAlert from "@/components/SimpleAlert.vue";
import NewsBubbly from "@/components/NewsBubbly.vue";
import SaveSeachDialog from "./monitoring/SaveSeachDialog.vue";
import { SearchInfo } from "@/store/search-module";
import { mapMutations, mapGetters, mapActions } from "vuex";
import { encode } from "@/utils/Base64";
import array from "@/utils/ArrayUtils";
import eventBus from "@/event-bus";
import searchApi from "@/services/SearchApi";
import SearchResponse from "@/types/SearchResponse";
import SearchesInfoApi from "@/services/SearchesInfoApi";
import { SinceOptionEnum } from "@/types/enum/SinceOptionEnum";
import { Client } from "@/types/enum/Client";
import LoginApi from "@/services/LoginApi";

let searchInfo = {} as SearchInfo;
const automaticSearchDelay = 1700;

export default Vue.extend({
  data() {
    return {
      requiredFilter: false,
      timeoutId: 0,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      unregister: [] as any[],
    };
  },
  components: {
    FilterPeriodPanel,
    FilterQueryPanel,
    FilterLanguage,
    FilterPlusOptionsPanel,
    SaveSeachDialog,
    NewsButton,
    SimpleAlert,
    NewsBubbly,
  },
  beforeCreate() {
    searchInfo = this.$store.state.search;
  },
  created() {
    eventBus.$on("doSearch", () => {
      this.search();
    });
    if (searchInfo.searchAction == "EDIT" && searchInfo.id != -1) {
      this.loadParam();
    } else {
      this.setSinceOption(SinceOptionEnum.LAST7DAYS);
      this.watchRegistry();
    }
  },
  beforeDestroy() {
    this.unregister.forEach((unWatch) => unWatch());
    eventBus.$off("doSearch");
    this.setDefaultValues();
  },
  computed: {
    showSaveSearch() {
      return this.$route.path.match("(search|news)");
    },
    disableBtnSaveSearch(): boolean {
      return this.isInvalidSearch() || this.isDisableFields();
    },
  },
  methods: {
    ...mapGetters("search", ["isInvalidSearch", "getCollectionOffset"]),
    ...mapGetters("user", ["isDisableFields"]),
    ...mapMutations("search", [
      "setDefaultValues",
      "setAdvancedQuery",
      "setAdvancedSearch",
      "setSinceOption",
      "setUntil",
      "setSince",
      "setSearchAction",
      "setDateField",
      "setSort",
      "setMySearchName",
      "setLang",
      "setMust",
      "setShould",
      "setNotContains",
      "setSources",
      "setTagsIds",
      "setGroupIds",
      "setLocalities",
      "setRestrictedSources",
      "setEdited",
      "setOffset",
      "setRestrictedLocalities",
      "setSearchLocked",
      "setSelectedCollections",
      "setCollectionOffsets",
      "setPublications",
      "setId",
      "setSavedCollections",
    ]),
    ...mapActions("visualization", ["clearVisualizationFields"]),
    watchRegistry() {
      if (this.unregister.length > 0) return;
      this.unregister.push(
        this.$store.watch(() => searchInfo.offset, this.searchOnChangeOffset)
      );
      this.unregister.push(
        this.$store.watch(
          () => searchInfo.selectedCollections,
          this.searchOnChangeArray
        )
      );
      if (this.isSearchOnChangeDisabled()) return;
      this.unregister.push(
        this.$store.watch(() => searchInfo.should, this.searchOnChangeArray)
      );
      this.unregister.push(
        this.$store.watch(() => searchInfo.must, this.searchOnChangeArray)
      );
      this.unregister.push(
        this.$store.watch(
          () => searchInfo.notContains,
          this.searchOnChangeArray
        )
      );
      this.unregister.push(
        this.$store.watch(() => searchInfo.sinceOption, this.searchOnChange)
      );
      this.unregister.push(
        this.$store.watch(() => searchInfo.since, this.searchOnChange)
      );
      this.unregister.push(
        this.$store.watch(() => searchInfo.until, this.searchOnChange)
      );
      this.unregister.push(
        this.$store.watch(() => searchInfo.lang, this.searchOnChangeArray)
      );
      this.unregister.push(
        this.$store.watch(() => searchInfo.sources, this.searchOnChangeIntArray)
      );
      this.unregister.push(
        this.$store.watch(() => searchInfo.groupIds, this.searchOnChangeArray)
      );
      this.unregister.push(
        this.$store.watch(() => searchInfo.tagsIds, this.searchOnChangeIntArray)
      );
      this.unregister.push(
        this.$store.watch(() => searchInfo.edited, this.searchOnChange)
      );
      this.unregister.push(
        this.$store.watch(() => searchInfo.dateField, this.searchOnChange)
      );
      this.unregister.push(
        this.$store.watch(() => searchInfo.sort, this.searchOnChange)
      );
      this.unregister.push(
        this.$store.watch(() => searchInfo.localities, this.searchOnChangeArray)
      );
    },
    searchOnChange(newValue: string, oldValue: string) {
      if (newValue !== oldValue) {
        this.search(true, automaticSearchDelay, true);
      }
    },
    searchOnChangeOffset(newValue: number, oldValue: number) {
      if (newValue !== oldValue) {
        this.search(false, 50, false);
      }
    },
    searchOnChangeArray(newValue: string[], oldValue: string[]) {
      if (!array.stringEquals(newValue, oldValue)) this.search(true, 50, true);
    },
    searchOnChangeIntArray(newValue: number[], oldValue: number[]) {
      if (!array.numberEquals(newValue, oldValue))
        this.search(true, automaticSearchDelay, true);
    },
    async loadParam() {
      try {
        let data = await SearchesInfoApi.getSearchInfoFromId(searchInfo.id);
        this.setMySearchName(data.mySearchName);

        this.setSelectedCollections([
          data.searchRequest.collectionOffsets[0].collection,
        ]);
        this.setSavedCollections(
          data.searchRequest.collectionOffsets.map((c) => c.collection)
        );
        this.setPublications([]);
        this.setAdvancedQuery(data.searchRequest.query || "");
        this.setAdvancedSearch(!data.searchRequest.simpleSearch);
        if (data.searchRequest.until) {
          this.setUntil(data.searchRequest.until);
        }
        this.setLang(data.searchRequest.langs);
        this.setMust(data.searchRequest.must || []);
        this.setShould(data.searchRequest.should || []);
        this.setNotContains(data.searchRequest.notContains || []);
        this.setGroupIds(data.searchRequest.groupIds);
        this.setLocalities(data.searchRequest.localities);
        this.setRestrictedSources(data.searchRequest.restrictedSources);
        this.setEdited(data.searchRequest.editedNews);
        this.setOffset(data.searchRequest.offset);
        this.setRestrictedLocalities(data.searchRequest.restrictedLocalities);
        this.setDateField("PUBLISHED_DATE");
        this.setSort(data.searchRequest.sort);
        this.setSources(data.searchRequest.sourceIds);
        this.setTagsIds(data.searchRequest.tagsIds);
        this.setSearchLocked(false);

        if (data.searchRequest.sinceOption) {
          this.setSinceOption(data.searchRequest.sinceOption);
        } else {
          this.setSinceOption(SinceOptionEnum.LAST7DAYS);
        }

        if (data.searchRequest.since) this.setSince(data.searchRequest.since);

        const searchBean = this.getSearchBean(false);
        this.$store.commit(
          "search/setGwtUrlParams",
          encode(JSON.stringify(searchBean))
        );
      } finally {
        this.watchRegistry();
      }
    },
    clickSearch() {
      this.search(false, 50, true);
    },
    search(silent = false, delay = 50, resetOffset = false) {
      this.clearVisualizationFields();

      this.updateUntil(silent);

      clearTimeout(this.timeoutId);
      if (resetOffset) {
        this.setOffset(0);
      }
      this.timeoutId = setTimeout(() => {
        if (searchInfo.searchLocked) {
          this.search(silent, 1000);
          return;
        }
        this.timeoutId = 0;
        if (this.$router.currentRoute.path !== "/news") {
          return;
        }
        const searchBean = this.getSearchBean(silent);
        this.$store.commit(
          "search/setGwtUrlParams",
          encode(JSON.stringify(searchBean))
        );
        searchApi
          .search(this.getSearchRequest(silent))
          .then(this.handleSearchResponse)
          .catch((e) => {
            if (e.status == 409) {
              alert(this.$t("error." + e.data.status).toString());
            } else {
              alert(e.data.status);
            }
          })
          .finally(() => this.setSearchLocked(false));
        this.setSearchLocked(true);
        this.$store.commit("search/setPublications", []);
      }, delay);
    },
    updateUntil(silent: boolean) {
      if (
        searchInfo.sinceOption !== "CUSTOM" &&
        searchInfo.offset == 0 &&
        this.timeoutId == 0 &&
        !silent
      ) {
        this.setUntil(new Date().getTime().toString());
      }
    },
    isSearchOnChangeDisabled() {
      return true;
    },
    handleSearchResponse(response: SearchResponse) {
      const { commit } = this.$store;
      commit("search/setCollectionOffsets", response.collectionOffsets);
      commit("search/setPublications", response.page);
      commit("search/setCount", response.count);
      if (!response.page || response.page.length == 0) {
        const withResult = response.collectionOffsets
          .filter((c) => c.count > 0)
          .map((c) => c.collection);
        if (withResult.length > 0) {
          this.setSelectedCollections([withResult[0]]);
        }
      }
    },
    getSearchBean(silent = false) {
      if (this.isInvalidSearch()) {
        this.requiredFilter = !silent;
        throw "Invalid search";
      }

      return {
        advancedSearch: searchInfo.advancedSearch,
        sinceOption: this.normalizeSinceOption(),
        dateType: searchInfo.dateField,
        advancedQuery: searchInfo.advancedQuery,
        sort: searchInfo.sort,
        lang: searchInfo.lang,
        since: searchInfo.sinceOption === "CUSTOM" ? searchInfo.since : null,
        until: searchInfo.until,
        mySearchName: searchInfo.mySearchName,
        mainSearchTerms: searchInfo.must,
        secondarySearchTerms: searchInfo.should,
        deleteSearchTerms: searchInfo.notContains,
        sources: searchInfo.sources,
        searchAction: "SEARCH",
        tagsIds: searchInfo.tagsIds,
        groupIds: searchInfo.groupIds,
        restrictedSources: searchInfo.restrictedSources,
        localities: searchInfo.localities,
        edited: this.getEdited(),
        offset: searchInfo.offset,
        restrictedLocalities: searchInfo.restrictedLocalities,
        collections: searchInfo.selectedCollections,
        timeZone: new Date().getTimezoneOffset(),
      };
    },
    getSearchRequest(silent = false) {
      if (this.isInvalidSearch()) {
        this.requiredFilter = !silent;
        throw "Invalid search";
      }

      return {
        simpleSearch: !searchInfo.advancedSearch,
        sinceOption: this.normalizeSinceOption(),
        searchAction: searchInfo.searchAction,
        dateField: searchInfo.dateField,
        query: searchInfo.advancedQuery,
        sort: searchInfo.sort,
        langs: this.normalizeLangFilter(),
        since: searchInfo.sinceOption === "CUSTOM" ? searchInfo.since : null,
        until: searchInfo.until,
        must: searchInfo.must,
        should: searchInfo.should,
        notContains: searchInfo.notContains,
        sourceIds: searchInfo.sources,
        tagsIds: searchInfo.tagsIds,
        groupIds: searchInfo.groupIds,
        restrictedSources: searchInfo.restrictedSources,
        localities: searchInfo.localities,
        editedNews: this.getEdited(),
        restrictedLocalities: searchInfo.restrictedLocalities,
        offset: searchInfo.offset,
        limit: 10,
        collectionOffsets: this.getCollectionOffset(),
        timeZone: new Date().getTimezoneOffset(),
      };
    },
    normalizeLangFilter() {
      if (!searchInfo.lang || searchInfo.lang.includes("all")) {
        return [];
      }
      return searchInfo.lang;
    },
    normalizeSinceOption() {
      if (!searchInfo.sinceOption) {
        return SinceOptionEnum.LAST7DAYS;
      }
      if (searchInfo.sinceOption === SinceOptionEnum.SINCELASTVISIT) {
        return SinceOptionEnum.LAST7DAYS;
      }
      return searchInfo.sinceOption;
    },
    normalizeNames(json: string) {
      return json
        .replace('"mainSearchTerms":', '"must":')
        .replace('"secondarySearchTerms":', '"should":')
        .replace('"deleteSearchTerms":', '"notContains":');
    },
    getEdited() {
      return searchInfo.edited === "edited"
        ? true
        : searchInfo.edited === "not_edited"
        ? false
        : null;
    },
  },
});
