
















import Vue from "vue";

export default Vue.extend({
  props: {
    label: { type: String },
    width: { type: String },
    filled: { type: Boolean, default: false },
    onclick: { type: Function },
    height: { type: String, default: "36px" },
    disabled: { type: Boolean, default: false },
  },
  data: () => {
    return {};
  },
});
