import { SinceOptionEnum } from "@/types/enum/SinceOptionEnum";
import Publication from "@/types/Publication";
import { CollectionOffset } from "@/types/SearchResponse";
import { Module } from "vuex";

export interface SearchInfo {
  id: number;
  advancedSearch: boolean;
  mySearchName: string;
  sinceOption: string;
  until: string;
  searchAction: string;
  dateField: string;
  advancedQuery: string;
  since: string;
  sort: string;
  lang: string[];
  must: string[];
  should: string[];
  notContains: string[];
  sources: number[];
  tagsIds: number[];
  groupIds: string[];
  restrictedSources: boolean;
  localities: [];
  edited: string;
  offset: number;
  count: number;
  countSelected: number;
  restrictedLocalities: boolean;
  searchLocked: boolean;
  gwtUrlParams: string;
  publications: Array<Publication>;
  selectedCollections: string[];
  savedCollections: string[];
  collectionOffsets: CollectionOffset[];
}

const getDefaults = (): SearchInfo => {
  const until = new Date();
  return {
    id: -1,
    mySearchName: "",
    advancedSearch: false,
    since: Date.now().toString(),
    until: until.getTime().toString(),
    sinceOption: SinceOptionEnum.SINCELASTVISIT,
    searchAction: "SEARCH",
    dateField: "PUBLISHED_DATE",
    advancedQuery: "",
    sort: "universal_date",
    lang: [],
    must: [],
    should: [],
    notContains: [],
    sources: [],
    tagsIds: [],
    groupIds: [],
    restrictedSources: true,
    localities: [],
    edited: "all",
    offset: 0,
    count: 0,
    countSelected: 0,
    restrictedLocalities: true,
    searchLocked: false,
    publications: new Array<Publication>(),
    gwtUrlParams: "",
    selectedCollections: [],
    savedCollections: [],
    collectionOffsets: new Array<CollectionOffset>(),
  };
};

const mutations = {
  setDefaultValues(state: SearchInfo): void {
    Object.assign(state, getDefaults());
  },
  setId(state: SearchInfo, selected: number): void {
    state.id = selected;
  },
  setAdvancedQuery(state: SearchInfo, selected: string): void {
    state.advancedQuery = selected;
  },
  setAdvancedSearch(state: SearchInfo, selected: boolean): void {
    state.advancedSearch = selected;
  },
  setRestrictedLocalities(state: SearchInfo, selected: boolean): void {
    state.restrictedLocalities = selected;
  },
  setSearchLocked(state: SearchInfo, selected: boolean): void {
    state.searchLocked = selected;
  },
  setSearchAction(state: SearchInfo, selected: string): void {
    state.searchAction = selected;
  },
  setGwtUrlParams(state: SearchInfo, selected: string): void {
    state.gwtUrlParams = selected;
  },
  setEdited(state: SearchInfo, selected: string): void {
    if (typeof selected == "string") {
      state.edited = selected;
    } else if (selected == null) {
      state.edited = "all";
    } else if (selected) {
      state.edited = "edited";
    } else {
      state.edited = "not_edited";
    }
  },
  setSinceOption(state: SearchInfo, selected: string): void {
    state.sinceOption = selected || SinceOptionEnum.LAST7DAYS;
  },
  setUntil(state: SearchInfo, selected: string): void {
    state.until = selected;
  },
  setDateField(state: SearchInfo, selected: string): void {
    state.dateField = selected;
  },
  setSort(state: SearchInfo, selected: string): void {
    state.sort = selected;
  },
  setMySearchName(state: SearchInfo, selected: string): void {
    state.mySearchName = selected;
  },
  setSince(state: SearchInfo, selected: string): void {
    state.since = selected;
  },
  setLang(state: SearchInfo, selected: []): void {
    state.lang = selected || [];
  },
  setMust(state: SearchInfo, selected: []): void {
    state.must = selected || [];
  },
  setShould(state: SearchInfo, selected: []): void {
    state.should = selected || [];
  },
  setNotContains(state: SearchInfo, selected: []): void {
    state.notContains = selected || [];
  },
  setSources(state: SearchInfo, selected: []): void {
    state.sources = selected || [];
  },
  setTagsIds(state: SearchInfo, selected: []): void {
    state.tagsIds = selected || [];
  },
  setGroupIds(state: SearchInfo, selected: []): void {
    state.groupIds = selected || [];
  },
  setLocalities(state: SearchInfo, selected: []): void {
    state.localities = selected || [];
  },
  setOffset(state: SearchInfo, selected: number): void {
    state.offset = selected;
  },
  setCount(state: SearchInfo, selected: number): void {
    state.count = selected;
  },
  setRestrictedSources(state: SearchInfo, selected: boolean): void {
    state.restrictedSources = selected;
  },
  setPublications(state: SearchInfo, selected: Publication[]): void {
    state.publications = selected;
  },
  setCollectionOffsets(state: SearchInfo, selected: CollectionOffset[]): void {
    state.collectionOffsets = selected ?? new Array<CollectionOffset>();
    if (selected.length == 0) {
      state.countSelected = 0;
    } else {
      state.countSelected = selected
        .filter((o) => !o.countOnly)
        .map((o) => o.count)
        .reduce((a, b) => a + b);
    }
  },
  setSelectedCollections(state: SearchInfo, selected: string[]): void {
    state.selectedCollections = selected;
  },
  setSavedCollections(state: SearchInfo, selected: string[]): void {
    state.savedCollections = selected;
  },
};

const getters = {
  isInvalidSearch(state: SearchInfo): boolean {
    if (state.advancedSearch && state.advancedQuery.trim().length > 0) {
      return false;
    }
    if (
      !state.advancedSearch &&
      (state.must.length > 0 || state.should.length > 0)
    ) {
      return false;
    }
    if (state.selectedCollections.length == 0) {
      return false;
    }
    if (!state.sinceOption) return false;
    if (state.sources.length > 0) return false;
    return true;
  },
  gwtUrlParams: (state: SearchInfo): string => state.gwtUrlParams,
  getCollectionOffset(state: SearchInfo): CollectionOffset[] {
    state.collectionOffsets.forEach(
      (o) => (o.countOnly = !state.selectedCollections.includes(o.collection))
    );
    return state.collectionOffsets;
  },
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const search: Module<SearchInfo, any> = {
  namespaced: true,
  state: getDefaults(),
  mutations,
  getters,
};

export { search, getDefaults };
