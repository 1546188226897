

































































































































import Vue from "vue";
import MySearchInfo from "@/types/MySearchInfo";

export default Vue.extend({
  name: "SearchList",
  data() {
    return {
      searchText: "",
      expanded: NaN,
    };
  },
  props: {
    searchList: { type: Array as () => Array<MySearchInfo> },
  },
  updated() {
    if (this.$route.query.searchId && !this.expanded) {
      const searchId = Number.parseInt(this.$route.query.searchId.toString());
      this.expanded = this.searchList.findIndex((s) => s.id === searchId);
    }
  },
  methods: {
    getSearchByNameList(): Array<MySearchInfo> {
      if (this.searchText == "") {
        return this.searchList;
      }
      return this.searchList.filter((MySearchInfo) =>
        MySearchInfo.name.toLowerCase().includes(this.searchText.toLowerCase())
      );
    },
  },
});
