



























import Vue from "vue";
import DialogReader from "@/components/NewsDialogHeader.vue";
import NewsButton from "@/components/NewsButton.vue";
export default Vue.extend({
  props: {
    onConfirm: { type: Function },
    onCancel: { type: Function },
    visible: { type: Boolean },
  },
  data() {
    return {
      dialog: this.visible,
    };
  },
  components: { DialogReader, NewsButton },
  watch: {
    visible() {
      this.dialog = this.visible;
    },
    dialog() {
      if (!this.dialog) {
        this.$emit("update:visible", false);
      }
    },
  },
});
