import Publication from "./Publication";

export class CollectionOffset {
  collection: string;
  count!: number;
  countOnly = false;
  cacheSize = 0;
  errorMsg!: string;

  constructor(collection: string) {
    this.collection = collection;
  }

  merge(other: CollectionOffset): void {
    if (this.collection !== other.collection) {
      throw "Merge between different collections";
    }
    this.count = other.count;
    this.cacheSize = other.cacheSize;
    this.errorMsg = other.errorMsg;
  }
}

export default interface SearchResponse {
  collectionOffsets: CollectionOffset[];
  page: Array<Publication>;
  offset: number;
  count: number;
  reportIds: string[];
  sinceOptionFilter: string;
}
