













import Vue from "vue";
import { mapGetters } from "vuex";
import eventBus from "@/event-bus";
import { SearchInfo } from "@/store/search-module";

const BASE_URL = "/NewsStream.html#";
let searchInfo = {} as SearchInfo;

export default Vue.extend({
  name: "GwtIframe",
  props: {
    context: { type: String, default: "news" },
    width: { type: String, default: "100%" },
    height: { type: String, default: "100%" },
  },
  data() {
    return {
      dynamicHeight: this.height,
    };
  },
  beforeCreate() {
    searchInfo = this.$store.state.search;
  },
  created() {
    window.addEventListener("message", this.updateHistory);
  },
  computed: {
    iframeUrl() {
      let gwtUrlPlusContext = BASE_URL + this.context;
      let bean = searchInfo.gwtUrlParams;
      if (this.context || this.$route.query.bean) {
        gwtUrlPlusContext = BASE_URL + this.context;
        bean = String(bean || this.$route.query.bean || "");
      }
      return [gwtUrlPlusContext, bean].filter(Boolean).join("=");
    },
  },
  destroyed() {
    window.removeEventListener("message", this.updateHistory);
  },
  methods: {
    ...mapGetters("user", ["isAskForMonitoringAfterReport"]),
    updateHistory({ data }: MessageEvent) {
      if (data.operation == "gwtUrlChange") {
        var msgData = data.data;
        if (msgData) {
          const context = this.getContext(msgData);
          this.$emit("update:context", context);
          const bean = this.getParam(msgData);
          this.$store.commit("search/setGwtUrlParams", bean);
          this.$router.push({
            path: "/" + context,
            query: {
              ...(bean && { bean }),
            },
          });
        }
      } else if (data.operation == "resize") {
        this.dynamicHeight = data.data ? data.data : "110vh";
      } else if (data.operation == "onClick") {
        window.dispatchEvent(new Event("click"));
      } else if (data.operation == "dataArrived") {
        this.$store.commit("search/setSearchLocked", false);
      } else if (data.operation == "set-last-report-template") {
        this.$store.commit(
          "user/setLastReportTemplate",
          data.data ? data.data : 2
        );
      } else if (
        data.operation == "reportWindowClosed" &&
        this.isAskForMonitoringAfterReport()
      ) {
        eventBus.$emit("doAskForMonitoring");
      }
    },
    getParam(queryString: string) {
      if (queryString.indexOf("=") > 0) {
        return queryString.substring(queryString.indexOf("=") + 1);
      }
      return "";
    },
    getContext(queryString: string) {
      if (queryString.indexOf("=") > 0) {
        return queryString.substring(0, queryString.indexOf("="));
      }
      return queryString;
    },
  },
});
