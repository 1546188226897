






























































import Vue from "vue";

export default Vue.extend({
  props: {
    msg: String,
    stepIndex: Number,
    nextEnable: Boolean,
    marginTop: { type: String, default: "0px" },
    showTooltip: { type: Boolean, default: true },
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    msgs() {
      return this.msg.split("\n");
    },
    fieldWidth() {
      return this.showTooltip ? "calc(100% + 15px)" : "100%";
    },
  },
  methods: {
    stepColor(index: number) {
      return this.stepIndex === index ? "primary" : "var(--color-text-light)";
    },
    next() {
      if (this.nextEnable) {
        this.$store.commit("user/setOnboardingStepIndex", this.stepIndex + 1);
      }
    },
    goToStepFive() {
      removeEventListener("click", this.goToStepFive);
      this.$store.commit("user/setOnboardingStepIndex", 5);
    },
  },
  beforeDestroy() {
    removeEventListener("click", this.goToStepFive);
  },
  created() {
    const user = this.$store.state.user;
    this.visible = user.onboardingStepIndex === this.stepIndex;
    this.$store.watch(
      () => user.onboardingStepIndex,
      (nextStep) => {
        this.visible = user.onboardingStepIndex === this.stepIndex;
        if (nextStep === 4) {
          setTimeout(() => {
            addEventListener("click", this.goToStepFive);
          }, 50);
        }
      }
    );
  },
});
