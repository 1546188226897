



















import Vue from "vue";
import store from "@/store";
import { SearchInfo } from "@/store/search-module";
let searchInfo = {} as SearchInfo;

export default Vue.extend({
  data() {
    return {};
  },
  beforeCreate() {
    searchInfo = this.$store.state.search;
  },
  computed: {
    edited: {
      get: () => searchInfo.edited || "all",
      set: (newValue) => store.commit("search/setEdited", newValue),
    },
  },
});
