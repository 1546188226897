/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Module } from "vuex";
import searchApi from "@/services/SearchApi";
import { CollectionEnum } from "@/types/enum/CollectionEnum";

const ALL_COLLECTIONS = [
  CollectionEnum.ONLINE.valueOf(),
  CollectionEnum.FLIP.valueOf(),
  CollectionEnum.OFFICIAL_JOURNAL.valueOf(),
  CollectionEnum.RADIO.valueOf(),
  CollectionEnum.TV.valueOf(),
  CollectionEnum.VIDEO.valueOf(),
  CollectionEnum.TWITTER.valueOf(),
  CollectionEnum.FACEBOOK.valueOf(),
  CollectionEnum.INSTAGRAM.valueOf(),
  CollectionEnum.YOUTUBE.valueOf(),
  //CollectionEnum.LINKEDIN.valueOf(),
];

export interface UserInfo {
  hasMonitoring: boolean;
  monitoringSaved: boolean;
  firstAccess: boolean;
  onboardingStepIndex: number;
  userExitEttempts: number;
  browserTabID: string;
  lastReportTemplate: number;
  allowedCollections: string[];
  countItemsInReport: number;
  isLogedIn: boolean;
  npsSurvey: number;
}

const state: UserInfo = {
  hasMonitoring: false,
  monitoringSaved: false,
  firstAccess: false,
  onboardingStepIndex: 0,
  userExitEttempts: 0,
  browserTabID: new Date().getTime().toString(),
  lastReportTemplate: 2,
  allowedCollections: [],
  countItemsInReport: 0,
  isLogedIn: false,
  npsSurvey: 0,
};

const mutations = {
  setHasMonitoring(state: UserInfo, selected: boolean): void {
    state.hasMonitoring = selected;
  },
  setOnboardingStepIndex(state: UserInfo, selected: number): void {
    if (selected > state.onboardingStepIndex && state.onboardingStepIndex > 0) {
      state.onboardingStepIndex = selected;
    }
  },
  enableOnboarding(state: UserInfo, enable: boolean): void {
    state.onboardingStepIndex = enable ? 1 : 0;
  },
  setFirstAccess(state: UserInfo, selected: boolean): void {
    state.firstAccess = selected;
  },
  setMonitoringSaved(state: UserInfo, selected: boolean): void {
    state.monitoringSaved = selected;
  },
  setUserExitEttempts(state: UserInfo, selected: number): void {
    state.userExitEttempts += selected;
  },
  setNpsSurvey(state: UserInfo, selected: number): void {
    state.npsSurvey += selected;
  },
  setLastReportTemplate(state: UserInfo, reportTemplate: number): void {
    state.lastReportTemplate = reportTemplate;
  },
  setAllowedCollections(state: UserInfo, selected: string[]): void {
    state.allowedCollections = selected;
  },
  setCountItemsInReport(state: UserInfo, selected: number): void {
    state.countItemsInReport = selected;
  },
  plusCountItemsInReport(state: UserInfo, selected: number): void {
    state.countItemsInReport = state.countItemsInReport + selected;
  },
  subtractCountItemsInReport(state: UserInfo, selected: number): void {
    state.countItemsInReport = Math.max(state.countItemsInReport - selected, 0);
  },
  setLogedIn(state: UserInfo, selected: boolean): void {
    state.isLogedIn = selected;
  },
};

const getters = {
  isOnboardingEnabled(state: UserInfo): boolean {
    return state.onboardingStepIndex > 0;
  },
  isDisableFields(state: UserInfo): boolean {
    return state.onboardingStepIndex > 0 && state.onboardingStepIndex < 4;
  },
  isShowOnboarding(state: UserInfo): boolean {
    return (
      !state.monitoringSaved && state.firstAccess && state.userExitEttempts < 2
    );
  },
  isAskForMonitoring(state: UserInfo): boolean {
    return (
      !state.hasMonitoring && !state.firstAccess && state.userExitEttempts < 2
    );
  },
  isAskForMonitoringAfterReport(state: UserInfo): boolean {
    return !state.hasMonitoring && state.userExitEttempts < 2;
  },
  getLastReportTemplate(state: UserInfo): number {
    return state.lastReportTemplate;
  },
  getCountItemsInReport(state: UserInfo): number {
    return state.countItemsInReport;
  },
};

const actions = {
  getAllowedCollections({ commit, state }: any): void {
    if (state.allowedCollections.length > 0) {
      return;
    }
    searchApi.allowedCollections().then((response) => {
      const collections = response.map((c) => c.toUpperCase());
      if (collections.includes("NEWS")) {
        collections.push(CollectionEnum.ONLINE);
        collections.push(CollectionEnum.FLIP);
        collections.push(CollectionEnum.OFFICIAL_JOURNAL);
      }
      if (collections.includes("SOCIAL-MEDIA")) {
        collections.push(CollectionEnum.FACEBOOK);
        collections.push(CollectionEnum.INSTAGRAM);
        collections.push(CollectionEnum.TWITTER);
        collections.push(CollectionEnum.YOUTUBE);
      }

      commit(
        "setAllowedCollections",
        ALL_COLLECTIONS.filter((c) => collections.includes(c))
      );
    });
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const user: Module<UserInfo, any> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

export { user };
