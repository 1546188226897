import Publication from "@/types/Publication";

const publicationImgErro = (
  tag: HTMLImageElement,
  publication: Publication
): void => {
  tag.style.display = "none";
};

export { publicationImgErro };
