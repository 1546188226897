

















import Vue from "vue";
import GwtPopup from "@/components/GwtPopup.vue";

export default Vue.extend({
  data() {
    return {
      show: false,
    };
  },
  components: { GwtPopup },
  mounted() {
    window.addEventListener("message", this.sourcesSelected);
  },
  destroyed() {
    window.removeEventListener("message", this.sourcesSelected);
  },
  computed: {
    showReportButton() {
      return this.$store.state.user.countItemsInReport > 0;
    },
  },
  methods: {
    showReportDialog() {
      this.show = true;
      window.postMessage({ operation: "showReportDialog" }, location.origin);
    },
    sourcesSelected({ data }: MessageEvent) {
      if (data.operation === "reportClosed") {
        this.show = false;
      } else if (data.operation === "gwt-clear-report") {
        this.show = false;
        this.$store.commit("user/setCountItemsInReport", 0);
      }
    },
  },
  watch: {
    show() {
      if (this.show === false) {
        window.postMessage({ operation: "closeReportDialog" }, location.origin);
      }
    },
  },
});
