

































import Vue from "vue";
import DialogHeader from "@/components/NewsDialogHeader.vue";
import ConfigAlert from "@/components/monitoring/ConfigAlert.vue";
import NewsButton from "@/components/NewsButton.vue";
import SimpleAlert from "@/components/SimpleAlert.vue";

import searchApi from "@/services/SearchApi";
import UserSearchAlertDto from "@/types/UserSearchAlertDto";

export default Vue.extend({
  props: { searchId: Number, show: { type: Boolean, default: false } },
  components: {
    DialogHeader,
    ConfigAlert,
    NewsButton,
    SimpleAlert,
  },
  data() {
    return {
      alerts: [] as UserSearchAlertDto[],
      dialog: false,
      errorOnConfigAlerts: false,
      error: false,
      suggestAlertTime: "15:00",
      suggestSmartTime: "06:00",
    };
  },
  methods: {
    close() {
      this.dialog = false;
      this.alerts = [] as UserSearchAlertDto[];
      this.$emit("close");
    },
    async getAlerts() {
      await searchApi
        .getAlertsBySearchId(this.searchId)
        .then((e) => {
          this.alerts = e;
          this.error = false;
          this.dialog = true;
        })
        .catch(() => {
          this.error = true;
          this.dialog = false;
        });
    },
    async saveAlerts() {
      setTimeout(this.doSave, 50);
    },
    async doSave() {
      (this.$refs.configAlert as Vue & { validate: () => boolean }).validate();

      if (!this.errorOnConfigAlerts) {
        searchApi
          .updateSearchAlerts(this.searchId, this.alerts)
          .then(() => {
            this.$emit(
              "updateSearchAlertIcon",
              this.searchId,
              this.alerts[0]?.alerts.length > 0 ||
                this.alerts[1]?.alerts.length > 0
            );
            this.dialog = false;
            this.error = false;
          })
          .catch(() => {
            this.error = true;
          });
      }
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.getAlerts();
      }
    },
    dialog() {
      if (!this.dialog) {
        this.$emit("update:show", this.dialog);
      }
    },
  },
});
