










































import Vue from "vue";
import DialogHeader from "@/components/NewsDialogHeader.vue";
import NewsButton from "@/components/NewsButton.vue";

export default Vue.extend({
  props: {
    resource: { type: String },
    visible: { type: Boolean },
  },
  data() {
    return {
      dialog: this.visible,
      hubspot: false,
    };
  },
  components: { DialogHeader, NewsButton },
  watch: {
    visible() {
      this.dialog = this.visible;
    },
    dialog() {
      if (!this.dialog) {
        this.$emit("update:visible", false);
      }
    },
  },
});
