



















































































































import Vue from "vue";

import Icon from "@/components/collection/Icon.vue";
import MediaDisplay from "@/components/search_results/MediaDisplay.vue";
import { VisualizationInfo } from "@/store/visualization-module";
import { mapActions } from "vuex";
import { CollectionEnum } from "@/types/enum/CollectionEnum";
import Publication from "@/types/Publication";
import { isSocialMedia } from "@/utils/CollectionUtils";
import SocialView from "./SocialView.vue";

let visualizationInfo = {} as VisualizationInfo;

export default Vue.extend({
  name: "NewsView",
  data() {
    return {
      alertMsg: "",
      scrollPosition: window.scrollY,
    };
  },
  components: { Icon, MediaDisplay, SocialView },
  beforeCreate() {
    visualizationInfo = this.$store.state.visualization;
  },
  created() {
    document.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollListener);
  },
  computed: {
    isSocialMedia(): boolean {
      return isSocialMedia(this.publication.collection);
    },
    publication(): Publication {
      return visualizationInfo.publication;
    },
    offsetHeight(): number {
      return visualizationInfo.offsetHeight;
    },
    fullContentLoading: () => visualizationInfo.fullContentLoading,
    topPosition(): string {
      return Math.max(140 - this.scrollPosition, 0) + "px";
    },
    height(): string {
      const diff = (this.scrollPosition / window.innerHeight) * 100;
      return Math.min(85 + diff, 100) + "vh";
    },
  },
  methods: {
    ...mapActions("visualization", ["clearVisualizationFields"]),
    scrollListener() {
      this.scrollPosition = window.scrollY;
    },
    isScrollMedia(isMedia: boolean) {
      const { collection } = this.publication;
      if (
        collection == CollectionEnum.FLIP ||
        collection == CollectionEnum.OFFICIAL_JOURNAL
      ) {
        return isMedia ? "scroll-container" : "not-scroll-container";
      }
      return isMedia ? "neutral" : "scroll-container";
    },
    parsedDate(publication: Publication) {
      if (publication.publishedDate) {
        return Date.parse(publication.publishedDate);
      }

      return Date.parse(publication.crawledDate);
    },
    getItemTitle(publication: Publication) {
      if (publication.title) {
        return publication.title;
      }
      if (publication.content) {
        return publication.content.substring(0, 100).concat("...");
      }
      const untitled = this.$t("no_title").toString();
      return untitled;
    },
    closeVisualization() {
      this.clearVisualizationFields();
    },
    onAlert(msg: string) {
      this.alertMsg = msg;
      setTimeout(() => {
        this.alertMsg = "";
      }, 4000);
    },
  },
});
