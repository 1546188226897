
















import Vue from "vue";
import KeyValuePair from "@/types/generic-types";

export default Vue.extend({
  name: "SelectLanguage",
  props: {
    lang: { type: String, default: null },
  },
  data() {
    return {
      languages: [] as KeyValuePair<string>[],
      langVal: "pt",
    };
  },
  created() {
    this.loadLocales();
  },
  mounted() {
    if (this.lang) {
      this.langVal = this.lang;
    } else {
      this.languages.forEach((lang) => {
        if (navigator.language.match(lang.value)) {
          this.langVal = lang.value;
          this.$emit("update:lang", this.langVal);
        }
      });
    }
  },
  methods: {
    loadLocales() {
      this.languages = [
        {
          value: "pt",
          text: this.$t("language.portuguese").toString(),
        },
        {
          value: "es",
          text: this.$t("language.spanish").toString(),
        },
        {
          value: "en",
          text: this.$t("language.english").toString(),
        },
      ];
    },
  },
  watch: {
    langVal() {
      this.$emit("update:lang", this.langVal);
    },
    lang() {
      this.langVal = this.lang;
    },
  },
});
