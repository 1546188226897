interface UserSearchAlertDto {
  searchAlertId: number;
  alerts: string[];
  additionalEmails: string[];
  smartNewsletter: boolean;
  language: string;
}

export default class UserSearchAlertDtoImpl implements UserSearchAlertDto {
  searchAlertId!: number;
  alerts: string[] = [];
  additionalEmails: string[] = [];
  smartNewsletter = false;
  language!: string;

  constructor(smartNewsLetter?: boolean) {
    if (smartNewsLetter) {
      this.smartNewsletter = smartNewsLetter;
    }
  }
}
