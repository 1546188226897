import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context("./i18n", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

export default new VueI18n({
  locale: navigator.language,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "pt-BR",
  silentFallbackWarn: true,
  messages: loadLocaleMessages(),
  dateTimeFormats: {
    "pt-BR": {
      short: {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      },
      long: {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      },
    },
    en: {
      short: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      long: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      },
    },
  },
});
