import { CollectionOffset } from "@/types/SearchResponse";
import httpClient from "./httpClient";

const END_POINT = "/charts/";

class ChartApi {
  async getLastSevenDays(
    searchId: number
  ): Promise<Map<string, CollectionOffset[]>> {
    const url = END_POINT.concat("monitoring/")
      .concat(searchId.toString())
      .concat("/lastSevenDays");

    const { data } = await httpClient
      .get<Map<string, CollectionOffset[]>>(url)
      .catch(() => {
        throw "Error getting LastSevenDays chart";
      });
    return new Map(Object.entries(data));
  }
}

export default new ChartApi();
