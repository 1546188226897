










































import Vue from "vue";
import filterPanel from "@/components/FilterPanel.vue";
import OnboardingDialog from "@/components/OnboardingDialog.vue";
import SearchResult from "@/components/search_results/SearchResult.vue";
import NewsView from "@/components/search_results/NewsView.vue";
import ReportButton from "@/components/ReportButton.vue";
import CollectionMenu from "@/components/collection/Menu.vue";
import SearchEmptyStateNoTerms from "@/components/empty-state/SearchEmptyStateNoTerms.vue";
import SearchEmptyStateNoResults from "@/components/empty-state/SearchEmptyStateNoResults.vue";
import SearchHelp from "@/components/search_results/SearchHelp.vue";

import eventBus from "@/event-bus";
import { mapActions, mapGetters, mapMutations } from "vuex";

const searchRoutes = ["/search", "/news", "/tv", "/radio", "/video"];

export default Vue.extend({
  name: "Search",
  data() {
    return {
      loading: false,
      initialEmptyState: true,
      searchHelp: false,
      unregister: [] as any[],
    };
  },
  computed: {
    ...mapGetters("user", ["isShowOnboarding", "isAskForMonitoring"]),
    ...mapGetters("search", ["isInvalidSearch"]),
  },
  mounted() {
    eventBus.$on("showAdvancedHelp", () => {
      this.searchHelp = !this.searchHelp;
    });
  },
  methods: {
    ...mapMutations("search", ["setDefaultValues"]),
    ...mapActions("visualization", ["clearVisualizationFields"]),
  },
  created() {
    const searchInfo = this.$store.state.search;
    if (searchInfo.id == -1 || searchInfo.searchAction == "SEARCH") {
      this.setDefaultValues();
      this.initialEmptyState = true;
    }
    const { bean } = this.$route.query;
    this.$store.commit("search/setGwtUrlParams", bean ? bean.toString() : "");
    this.clearVisualizationFields();
    this.unregister.push(
      this.$store.watch(
        () => searchInfo.searchLocked,
        (newValue) => {
          this.loading = newValue;
          this.searchHelp = false;
          this.initialEmptyState = false;
          if (newValue) window.scrollTo(0, 0);
        }
      )
    );
  },
  beforeDestroy() {
    this.unregister.forEach((unWatch) => unWatch());
    eventBus.$off("showAdvancedHelp");
  },
  components: {
    filterPanel,
    OnboardingDialog,
    SearchResult,
    NewsView,
    CollectionMenu,
    ReportButton,
    SearchEmptyStateNoTerms,
    SearchEmptyStateNoResults,
    SearchHelp,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isInvalidSearch) {
      this.$store.commit("user/enableOnboarding", false);
      next();
      return;
    } else if (searchRoutes.includes(to.path)) {
      next();
      return;
    }
    this.$store.commit("user/setUserExitEttempts", 1);
    if (this.isAskForMonitoring) {
      eventBus.$emit("doAskForMonitoring", () => {
        this.$store.commit("user/enableOnboarding", false);
        next();
      });
    } else if (this.isShowOnboarding) {
      eventBus.$emit("doShowOnboarding", () => {
        this.$store.commit("user/enableOnboarding", false);
        next();
      });
    } else {
      this.$store.commit("user/enableOnboarding", false);
      next();
    }
  },
});
