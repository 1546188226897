


































import Vue from "vue";
import FilterSource from "@/components/FilterSource.vue";
import FilterEdited from "@/components/FilterEdited.vue";
import store from "@/store";
import { mapGetters } from "vuex";
import { SearchInfo } from "@/store/search-module";
let searchInfo = {} as SearchInfo;

export default Vue.extend({
  data() {
    return {
      viewMore: false,
    };
  },
  components: { FilterSource, FilterEdited },
  beforeCreate() {
    searchInfo = this.$store.state.search;
  },
  methods: {
    ...mapGetters("user", ["isDisableFields"]),
  },
  computed: {
    sort: {
      get: () => searchInfo.sort,
      set: (newValue) => store.commit("search/setSort", newValue),
    },
  },
});
