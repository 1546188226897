

























import Vue from "vue";

import DialogHeader from "@/components/NewsDialogHeader.vue";
import NewsButton from "@/components/NewsButton.vue";
import SimpleAlert from "@/components/SimpleAlert.vue";

import SearchesInfoApi from "@/services/SearchesInfoApi";

export default Vue.extend({
  name: "DeleteDialog",
  props: {
    searchId: { type: Number },
    show: { type: Boolean, default: false },
  },
  components: {
    DialogHeader,
    NewsButton,
    SimpleAlert,
  },
  data() {
    return {
      dialog: false,
      error: false,
    };
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit("close");
    },
    async deleteSearch() {
      setTimeout(this.doDelete, 50);
    },
    async doDelete() {
      SearchesInfoApi.deleteSearch(this.searchId)
        .then(() => {
          this.dialog = false;
          this.error = false;
          this.$emit("reloadSearchList");
        })
        .catch(() => {
          this.error = true;
        });
    },
    openDialog(): void {
      this.dialog = true;
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.openDialog();
      }
    },
    dialog() {
      if (!this.dialog) {
        this.$emit("update:show", this.dialog);
      }
    },
  },
});
