



































import Vue from "vue";
import searchApi from "@/services/SearchApi";
import KeyValuePair from "@/types/generic-types";
import store from "@/store";
import { SearchInfo } from "@/store/search-module";
import { mapGetters } from "vuex";
let searchInfo = {} as SearchInfo;

export default Vue.extend({
  data() {
    return {
      languages: [] as KeyValuePair<string>[],
      allAlreadySelected: false,
    };
  },
  beforeCreate() {
    searchInfo = this.$store.state.search;
  },
  created() {
    this.loadLocales();
  },
  computed: {
    selectedLangs: {
      get: () => searchInfo.lang,
      set: (newValue) => store.commit("search/setLang", newValue),
    },
  },
  watch: {
    selectedLangs() {
      if (
        this.selectedLangs &&
        this.selectedLangs.length > 1 &&
        this.selectedLangs.indexOf("all") > -1
      ) {
        this.selectedLangs = !this.allAlreadySelected
          ? ["all"]
          : this.selectedLangs.filter((i) => i != "all");
        this.allAlreadySelected = !this.allAlreadySelected;
      }
    },
  },
  methods: {
    ...mapGetters("user", ["isDisableFields"]),
    deleteLangByChip(item: { value: string }): void {
      this.selectedLangs = this.selectedLangs.filter((i) => i != item.value);
    },
    onFocus() {
      if (this.$store.state.user.onboardingStepIndex === 4) {
        this.$store.commit("user/setOnboardingStepIndex", 5);
      }
      this.loadLocales();
    },
    async loadLocales() {
      if (this.languages.length == 0) {
        const response = await searchApi.getLacales(this.$i18n.locale);
        this.languages = response.map((dto) => {
          return { value: dto.language, text: dto.name };
        });
      }
    },
  },
});
