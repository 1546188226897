






















import Vue from "vue";
import DialogReader from "@/components/NewsDialogHeader.vue";
import NewsButton from "@/components/NewsButton.vue";
export default Vue.extend({
  props: {
    onClose: { type: Function },
    visible: { type: Boolean },
    title: { type: String, default: "Ooops" },
    msg: { type: String },
  },
  data() {
    return {
      dialog: this.visible,
    };
  },
  methods: {
    doClose() {
      if (this.onClose) this.onClose();
      this.dialog = false;
    },
    lines() {
      return this.msg.split("\n");
    },
  },
  components: { DialogReader, NewsButton },
  watch: {
    visible() {
      this.dialog = this.visible;
    },
    dialog() {
      if (!this.dialog) {
        this.$emit("update:visible", false);
      }
    },
  },
});
