







import Vue from "vue";
import GwtIframe from "@/components/GwtIframe.vue";

export default Vue.extend({
  name: "GWTPopup",
  props: {
    show: Boolean,
    context: { type: String, default: "news" },
    height: { type: String, default: "643px" },
    width: { type: String, default: "553px" },
  },
  data() {
    return {
      dialog: this.show,
    };
  },
  components: { GwtIframe },
  methods: {
    updateDialog() {
      this.dialog = this.show;
    },
  },
  watch: {
    show() {
      this.updateDialog();
    },
    dialog() {
      this.$emit("update:show", this.dialog);
    },
  },
});
