import { CollectionEnum } from "@/types/enum/CollectionEnum";

const iconColor = (
  selected: boolean,
  count: number,
  collection: string,
  isSnippet: boolean
): string => {
  if (selected && count > 0) {
    switch (collection) {
      case CollectionEnum.INSTAGRAM:
        return "url(#radial-gradient)";
      case CollectionEnum.TWITTER:
        return "#03a9f4";
      case CollectionEnum.FACEBOOK:
        return "#414cce";
      case CollectionEnum.YOUTUBE:
        return "#f33d00";
      default:
        return isSnippet ? "#282e37" : "var(--color-theme)";
    }
  }
  return "#828282";
};

const isSocialMedia = (collection: string): boolean => {
  switch (collection) {
    case CollectionEnum.INSTAGRAM:
    case CollectionEnum.TWITTER:
    case CollectionEnum.FACEBOOK:
    case CollectionEnum.YOUTUBE:
      return true;
    default:
      return false;
  }
};

export { iconColor, isSocialMedia };
