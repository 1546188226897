


























import Vue from "vue";
export default Vue.extend({
  name: "WelcomeDialog",
  props: {
    show: Boolean,
  },
  data() {
    return {
      dialog: this.show,
    };
  },
  methods: {
    updateDialog() {
      this.dialog = this.show;
    },
    startOnboarding() {
      this.$store.commit("user/enableOnboarding", true);
      this.dialog = false;
    },
  },
  watch: {
    show() {
      this.updateDialog();
    },
  },
});
