



















































































import Vue from "vue";
import { mapActions } from "vuex";
import LoginApi from "@/services/LoginApi";
import OnboardingControlApi from "@/services/OnboardingControlApi";
import WelcomeDialog from "./WelcomeDialog.vue";

export default Vue.extend({
  name: "Navbar",
  data() {
    return {
      showWelcome: false,
    };
  },
  components: { WelcomeDialog },
  computed: {
    optionsTab() {
      switch (this.$route.path) {
        case "/emailGroup":
        case "/flipStand":
        case "/consumption":
        case "/account":
          return this.$route.path;
        default:
          return "";
      }
    },
    searchTab() {
      switch (this.$route.path) {
        case "/news":
        case "/radio":
        case "/tv":
        case "/video":
          return this.$route.path;
        default:
          return "/news";
      }
    },
    reportTab() {
      switch (this.$route.path) {
        case "/smartReports":
          return this.$route.path;
        default:
          return "/myReport";
      }
    },
    faqLink() {
      return this.$t("menu.faq_link");
    },
  },
  methods: {
    ...mapActions("user", ["getAllowedCollections"]),
    logout: LoginApi.logout,
    async checkOnboardingShow() {
      let sessionInfo = JSON.parse(
        JSON.stringify(this.$cookies.get("newsstream-user"))
      );

      const response = await OnboardingControlApi.shouldShowOnboarding(
        sessionInfo
      );
      this.$store.commit("user/setHasMonitoring", response.hasMonitoring);
      this.$store.commit("user/setFirstAccess", response.firstAccess);
      this.$store.commit("user/setNpsSurvey", response.npsSurvey);
      if (response.npsSurvey > 0) {
        this.$router.push({
          path: "/survey",
        });
        return;
      }

      if (this.$route.query.fromLogin || this.$route.fullPath === "/") {
        this.$router.push({
          path: "/" + response.redirectTo,
        });
        this.showWelcome = response.showWelcome;
      }
    },
  },
  mounted() {
    this.getAllowedCollections();
    this.checkOnboardingShow();
    if (this.$route.query.onboarding) {
      this.$router.push({
        path: "/news",
      });
      this.showWelcome = true;
    }
  },
});
