import httpClient from "./httpClient";
import store from "@/store";
import MySearchInfo, { MySearchInfoResponse } from "@/types/MySearchInfo";
import SearchResponse from "@/types/SearchResponse";
import qs from "qs";

const END_POINT = "/searches/";

class SearchInfoApi {
  async getSearchList(): Promise<Array<MySearchInfo>> {
    const response = await httpClient
      .get(END_POINT, {
        params: {
          browserTabID: store.state.user.browserTabID,
        },
      })
      .catch((error) => {
        throw error.response;
      });
    return response.data;
  }
  async deleteSearch(searchId: number): Promise<void> {
    await httpClient
      .delete(END_POINT.concat(searchId.toString()), {
        params: {
          browserTabID: store.state.user.browserTabID,
        },
      })
      .catch((error) => {
        throw error.response;
      });
  }
  async getPublicationsBySearchId(
    searchId: number,
    sinceOption: string,
    listCollections: Array<string>,
    offset: number
  ): Promise<SearchResponse> {
    const response = await httpClient
      .get(END_POINT.concat(searchId.toString()).concat("/publications"), {
        params: {
          browserTabID: store.state.user.browserTabID,
          sinceOption: sinceOption,
          collections: listCollections,
          offset: offset,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false });
        },
      })
      .catch((error) => {
        throw error.response;
      });
    return response.data;
  }
  async getSearchesNotification(
    searchIds: Array<number>
  ): Promise<Array<MySearchInfo>> {
    const response = await httpClient.get(END_POINT.concat("notifications"), {
      params: {
        ids: searchIds,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    });

    return response.data;
  }
  async getSearchInfoFromId(searchId: number): Promise<MySearchInfoResponse> {
    const response = await httpClient
      .get(END_POINT.concat(searchId.toString()).concat("/info"), {
        params: {
          browserTabID: store.state.user.browserTabID,
        },
      })
      .catch((error) => {
        throw error.response;
      });
    return response.data;
  }
}

export default new SearchInfoApi();
