











import Vue from "vue";
export default Vue.extend({
  props: {
    title: { type: String },
    onClose: { type: Function },
  },
});
