const equals = (a: [], b: []): boolean => {
  return a.length === b.length && a.every((val, index) => val === b[index]);
};

const numberEquals = (a: number[], b: number[]): boolean => {
  return equals(a as [], b as []);
};
const stringEquals = (a: string[], b: string[]): boolean => {
  return equals(a as [], b as []);
};

export default { equals, numberEquals, stringEquals };
