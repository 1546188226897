import Vue from "vue";
import Vuex from "vuex";
import { search } from "./search-module";
import { user } from "./user-module";
import { visualization } from "./visualization-module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user: user,
    search: search,
    visualization: visualization,
  },
});
